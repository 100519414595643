// @flow
import * as React from 'react';

import {Animated} from 'react-native';
import type {CompositeAnimation} from 'react-native/Libraries/Animated/src/AnimatedImplementation';
import type {ViewStyleProp} from 'react-native/Libraries/StyleSheet/StyleSheet';

export function useFadeOut(
  millis: number
): [ViewStyleProp, CompositeAnimation] {
  return useFade(millis, 1, 0);
}

export function useFadeIn(millis: number): [ViewStyleProp, CompositeAnimation] {
  return useFade(millis, 0, 1);
}

export function useFade(
  millis: number,
  from: number,
  to: number
): [ViewStyleProp, CompositeAnimation] {
  const opacity = React.useRef(new Animated.Value(from));

  const animation = Animated.timing(opacity.current, {
    toValue: to,
    duration: millis,
    useNativeDriver: false,
  });

  return [{opacity: opacity.current}, animation];
}

function animateValueTo(
  value: Animated.Value,
  toValue: number,
  duration: number
) {
  return Animated.timing(value, {
    toValue,
    duration,
    useNativeDriver: false,
  });
}

export function usePulse(
  steps: number[],
  speed: number
): [ViewStyleProp, CompositeAnimation] {
  const scale = React.useRef(new Animated.Value(0));

  const animation = Animated.sequence(
    steps.map((step) => animateValueTo(scale.current, step, speed))
  );

  return [{transform: [{scale: scale.current}]}, animation];
}

// $FlowFixMe
export function SlideAnimation({current, next, inverted, layouts: {screen}}) {
  const progress = Animated.add(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
    next
      ? next.progress.interpolate({
          inputRange: [0, 1],
          outputRange: [0, 1],
          extrapolate: 'clamp',
        })
      : 0
  );

  const outputRange: number[] = [-screen.width, 0, screen.width];

  return {
    cardStyle: {
      transform: [
        {
          translateX: Animated.multiply(
            progress.interpolate({
              inputRange: [0, 1, 2],
              outputRange,
              extrapolate: 'clamp',
            }),
            inverted
          ),
        },
      ],
    },
  };
}
