// @flow

import * as React from 'react';
import {type Screen} from './lib/Screen';
import {View, ScrollView} from 'react-native';
import {useNav} from './lib/Nav';
import BigButton from './BigButton';
import Send from './Send';
import {InfoText} from './Styles';
import {MiddleText} from './Styles';
import BottomNav from './BottomNav';
import {daysLeftString} from './lib/Stuff';
import Reach from './Reach';

const Share: Screen<{}> = () => {
  const nav = useNav();
  const showReach = true;

  return (
    <View style={S.container}>
      <ScrollView
        contentContainerStyle={{flex: 1, alignItems: 'stretch', padding: 32}}
      >
        <View style={{maxWidth: 500, alignSelf: 'center'}}>
          <MiddleText style={{fontWeight: 'bold'}}>
            ONLY {daysLeftString().toUpperCase()} !!!
          </MiddleText>
          <MiddleText top={6}>
            Remind your friends and family to vote
          </MiddleText>
          <View style={{marginTop: 32, width: 320, alignSelf: 'center'}}>
            <BigButton
              label="Pick a Message to Send"
              onPress={() => nav.push(Send)}
            />
          </View>
          <InfoText top={24}>
            Even if your friends and family are all voting, please spread the
            word... they have friends who need a reminder, too.
          </InfoText>
          {showReach && <Reach />}
        </View>
      </ScrollView>
      <BottomNav planView="vote" />
    </View>
  );
};

const S = {
  bigCount: {
    fontFamily: 'RobotoSlab_700Bold',
    fontSize: 40,
    textAlign: 'center',
  },
  container: {
    flex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
  },
};

Share.id = 'Share';
Share.title = 'Spread the Vote ♥';

export default Share;
