// @flow
import * as React from 'react';
import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import Enter from './Enter';
import Splash from './Splash';
import {stackScreensFor} from './lib/Nav';
import {AuthProvider} from './lib/Auth';
import {initFirebase} from './lib/Firebase';
import {Provider as PaperProvider, DefaultTheme} from 'react-native-paper';
import {
  useFonts,
  RobotoSlab_700Bold,
  RobotoSlab_400Regular,
} from '@expo-google-fonts/roboto-slab';
import {AppLoading} from 'expo';
import Vote from './Vote';
import Share from './Share';
import Send from './Send';
import {useNav} from './lib/Nav';
import {firebaseAuthApi} from './lib/FirebaseAuth';
import Account from './Account';
import Dev, {DEVMODE, getChannel} from './Dev';
import {getBool} from './lib/Storage';
import type {Action} from './lib/Actions';
import {SlideAnimation} from './lib/Animation';
import {logView} from './lib/Logging';
import Soon from './Soon';
import Privacy from './Privacy';
import Spread from './Spread';
import Plan from './Plan';
import MyPlan from './MyPlan';
import SharePlan from './SharePlan';

const Stack = createStackNavigator();
const MyTheme = {
  ...DefaultTheme,
  roundness: 6,
  colors: {
    ...DefaultTheme.colors,
    primary: '#202868',
    accent: '#03A9F4',
    text: '#212121',
    background: '#F8F8F8',
  },
};

const SCREENS = [
  Enter,
  Splash,
  Vote,
  Share,
  Send,
  Account,
  Dev,
  Soon,
  Privacy,
  Spread,
  Plan,
  MyPlan,
  SharePlan,
];

const DEV_ACTIONS = [
  {
    id: 'RELOAD',
    icon: 'refresh',
    label: 'Reload',
    handle: () => {
      const nav = useNav();
      return () => {
        nav.push(Splash);
      };
    },
  },

  {
    id: 'DEV',
    icon: 'tune',
    label: 'Dev Settings',
    handle: () => {
      const nav = useNav();
      return () => {
        nav.push(Dev);
      };
    },
  },
];

function getActions(): Action[] {
  return getBool(DEVMODE) ? DEV_ACTIONS : [];
}

// When clicking on shared link, EnteredFrom == 'share'
type EntryType = 'nav' | 'share' | 'navsend' | 'navexp' | 'plan';
let entryType: EntryType = 'navexp';

export function setSiteEntryType(type: EntryType) {
  entryType = type;
}
export function siteEntryType(): EntryType {
  return entryType;
}

const LINKING = {};

const TEST_FIREBASE_CONFIG = {
  projectId: 'dudegovote', // 91992832189 ?
  apiKey: 'AIzaSyAHcMVvpWMH44HXTKolhsfnbmbB0V94Zvg',
  appId: '1:91992832189:web:19457b5f88db6eb6f99fd4',
  measurementId: 'G-YNNL11GZ29',
};

const FIREBASE_CONFIG = {
  projectId: 'dudegovote', // 91992832189 ?
  apiKey: 'AIzaSyAHcMVvpWMH44HXTKolhsfnbmbB0V94Zvg',
  appId: '1:91992832189:web:3bae8cf08ee8aa10f99fd4',
  measurementId: 'G-DEHYR98H2Y',
};

// Ideally this wouldn't be synchronous on page load. Oh well...
initFirebase(getChannel() == 'prod' ? FIREBASE_CONFIG : TEST_FIREBASE_CONFIG);
const FIREBASE_AUTH = firebaseAuthApi();

const NAV_OPTS = {
  cardStyleInterpolator: SlideAnimation,
};

function SpreadTheVote(): React.Node {
  const [fontsLoaded] = useFonts({RobotoSlab_400Regular, RobotoSlab_700Bold});
  const [userLoaded, setUserLoaded] = React.useState(false);
  const nav = React.useRef();

  FIREBASE_AUTH.getUser().then(() => setUserLoaded(true));

  if (!fontsLoaded || !userLoaded) {
    return <AppLoading />;
  }

  function log() {
    logView(nav.current);
  }

  return (
    <AuthProvider api={FIREBASE_AUTH}>
      <PaperProvider theme={MyTheme}>
        <NavigationContainer
          ref={nav}
          linking={LINKING}
          onStateChange={log}
          onReady={log}
        >
          <Stack.Navigator initialRouteName="Splash" screenOptions={NAV_OPTS}>
            {stackScreensFor(Stack, SCREENS, getActions)}
          </Stack.Navigator>
        </NavigationContainer>
      </PaperProvider>
    </AuthProvider>
  );
}

export default SpreadTheVote;
