// @flow

import * as React from 'react';
import {type Screen} from './lib/Screen';
import {View, ScrollView, Text} from 'react-native';
import {useNav} from './lib/Nav';
import BigButton from './BigButton';
import {InfoText, BulletText} from './Styles';
import Share from './Share';
import {Animated} from 'react-native';
import {useFadeIn} from './lib/Animation';
import Info from './Info';
import BottomNav from './BottomNav';
import {useAccount} from './lib/Auth';
import {useAuth} from './lib/Auth';
import {type VotePlan, VoteApi} from './Api';
import {logEvent} from './lib/Logging';
// $FlowFixMe
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {TouchableRipple} from 'react-native-paper';
import {setUserData} from './lib/Firestore';
import type {ViewStyleProp} from 'react-native/Libraries/StyleSheet/StyleSheet';

type Props = {
  async: {
    votes: number,
    state: string,
    plan: VotePlan,
  },
};

const ChecklistItem = (props: {
  set: boolean,
  text: string,
  onPress: () => void,
}) => {
  const {set, text, onPress} = props;

  // $FlowFixMe
  const rowStyle: ViewStyleProp = S.checkrow;

  return (
    <TouchableRipple onPress={onPress}>
      <View style={rowStyle}>
        <View>
          <Icon name="checkbox-blank-outline" size={48} color="#202868" />
          {set && (
            <Icon
              name="heart"
              size={30}
              color="#B00"
              style={{position: 'absolute', left: 9, top: 9}}
            />
          )}
        </View>
        <View style={{width: 11}} />
        <BulletText>{text}</BulletText>
      </View>
    </TouchableRipple>
  );
};

const Plan: Screen<Props> = (props: Props) => {
  const [plan, setPlan] = React.useState(props.async.plan);
  const {registered, where, voted} = plan;
  const [drawAttention, setDrawAttention] = React.useState();
  const [voteCount, setVoteCount] = React.useState(props.async.votes);
  const initialState = props.async.state.toLowerCase();
  const user = useAccount();

  function randomVoters() {
    if (Math.random() > 0.9) {
      setVoteCount(voteCount + 1);
    }
  }

  React.useEffect(() => {
    const interval = setInterval(randomVoters, 3000);
    return () => clearInterval(interval);
  });

  React.useEffect(() => {
    if (user) {
      VoteApi.recordEvent(user.id, 'planning');
    }
  }, [user]);

  function updatePlan(newValues: $Shape<VotePlan>) {
    const newPlan = {...plan, ...newValues};
    if (user) {
      setUserData(user.id, 'plan', newPlan);
    }
    setPlan(newPlan);
  }

  function toggleVote() {
    (async () => {
      if (registered) {
        logEvent('action_undo_voting');
      } else {
        logEvent('action_voting');
      }
      if (user) {
        await Promise.all([
          VoteApi.setVoting(user.id, !registered),
          registered
            ? VoteApi.decrementVoteCount()
            : VoteApi.incrementVoteCount(),
          VoteApi.recordEvent(user.id, 'voting'),
        ]);
        const delta = registered ? -1 : 1;
        setVoteCount(voteCount + delta);
      }
      updatePlan({registered: !registered});
    })();
  }

  function toggleWhere(value: string) {
    if (!where) {
      setDrawAttention(true);
      updatePlan({where: value});
    } else {
      updatePlan({where: null});
    }
  }

  return (
    <View style={S.container}>
      <ScrollView
        contentContainerStyle={{
          padding: 32,
          paddingTop: 26,
        }}
      >
        <Text style={S.bigCount}>{voteCount.toLocaleString()} Voters</Text>
        <InfoText>...and counting</InfoText>
        <View style={{height: 12}} />
        <ChecklistItem
          set={registered}
          text="I'm Registered"
          onPress={() => toggleVote()}
        />
        {!registered && (
          <View style={{flexDirection: 'row'}}>
            <View style={{width: 56}} />
            <InfoText style={{textAlign: 'left', marginTop: -8}}>
              In some states you can register up until election day
            </InfoText>
          </View>
        )}
        {where != 'inperson' && (
          <ChecklistItem
            set={where == 'bymail'}
            text="I'm Voting by Mail"
            onPress={() => toggleWhere('bymail')}
          />
        )}
        {where != 'bymail' && (
          <ChecklistItem
            set={where == 'inperson'}
            text="I'm Voting in Person"
            onPress={() => toggleWhere('inperson')}
          />
        )}
        <ChecklistItem
          set={voted}
          text="I Voted !!!"
          onPress={() => updatePlan({voted: !voted})}
        />
        <View style={{height: 8}} />
        <Info initialState={initialState} />
        {false && <YourTurn />}
      </ScrollView>
      <BottomNav drawAttention={drawAttention} planView="plan" />
    </View>
  );
};

const YourTurn = () => {
  const nav = useNav();
  const [fadeIn, animation] = useFadeIn(2000);

  animation.start();

  return (
    <Animated.View style={fadeIn}>
      <View>
        <BigButton
          style={{alignSelf: 'center'}}
          labelStyle={{fontSize: 32}}
          uppercase={false}
          label="Your Turn Now ♥"
          onPress={() => nav.push(Share)}
        />
      </View>
    </Animated.View>
  );
};

const S = {
  button: {
    width: 280,
    alignSelf: 'center',
    marginHorizontal: 12,
  },
  bigCount: {
    fontFamily: 'RobotoSlab_700Bold',
    fontSize: 40,
    textAlign: 'center',
  },
  container: {
    flex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
  },
  checktext: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  checkrow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: -4,
    cursor: 'pointer',
  },
};

Plan.id = 'Plan';
Plan.title = 'Plan Your Vote';

Plan.load = async () => {
  // Ensure user is loaded by awaiting call
  const user = await useAuth().getUser();

  const getState = async () => {
    const result = await VoteApi.geocode();
    if (result.country !== 'US') {
      return 'PA';
    }
    return result.region || 'PA';
  };

  const [votes, state, plan] = await Promise.all([
    VoteApi.getVoteCount(),
    getState(),
    VoteApi.getPlan(user?.id),
  ]);

  return {votes, state, plan};
};

export default Plan;
