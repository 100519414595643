// @flow

import * as firebase from 'firebase/app';
import 'firebase/firestore';
import {getChannel} from '../Dev';

const DATABASE_PATHS = {
  dev: 'test',
  staging: 'test',
  prod: 'prod',
};

function getDatabasePath() {
  console.log(getChannel());
  return '/db/' + DATABASE_PATHS[getChannel()];
}

// TODO: better naming
export async function getUserData(
  userId: string,
  dataId: string,
  uncached: boolean = false
): any {
  if (!uncached) {
    const cached = getCachedData(userId, dataId);
    if (cached) {
      return cached;
    }
  }
  const doc = await users().doc(userId).get();
  return doc.get(dataId);
}

export async function setUserData(userId: string, dataId: string, value: any) {
  const doc = users().doc(userId);
  const obj = {};
  obj[dataId] = value;
  await doc.set(obj, {merge: true});
  setCachedData(userId, dataId, value);
}

function users() {
  const firestore = firebase.firestore();
  return firestore.collection(getDatabasePath() + '/users');
}

function globalDb() {
  const firestore = firebase.firestore();
  return firestore.collection(getDatabasePath() + '/global');
}

export async function getGlobal(dataId: string): any {
  const doc = await globalDb().doc(dataId).get();
  return doc.get('value');
}

export async function setGlobal(dataId: string, value: any) {
  const doc = await globalDb().doc(dataId);
  await doc.set({value}, {merge: true});
}

// Dumbest in-memory cache ever
const cache = {};

function getCachedData(userId: string, dataId: string) {
  return cache[userId + '||' + dataId];
}

function setCachedData(userId: string, dataId: string, value: any) {
  cache[userId + '||' + dataId] = value;
}
