//@flow

import * as Analytics from 'expo-firebase-analytics';

export function nonNull<T>(
  value: ?T,
  msg: string = 'Value must not be null'
): T {
  if (value == null) {
    throw Error(msg);
  }
  return value;
}

export function getDaysLeft(): number {
  const electionDay = new Date('2020-11-03 00:00:00 PST');
  const diff = electionDay - Date.now();
  const days = Math.round(diff / 3600 / 24 / 1000);
  return days;
}

export function daysLeftString(): string {
  const daysLeft = getDaysLeft();
  return daysLeft == 1 ? '1 day left' : daysLeft + ' days left';
}

const MESSAGE_IDS = ['numusers', 'exponentialmath', 'needreminding'];

const MESSAGES = {
  numusers: 'NNN people have already Spread the Vote ♥ Will you?',
  exponentialmath:
    'Do your part to get out the vote! If we all spread the vote to three people we know, we will reach 1.6 million people by election day.',
  needreminding:
    'Even if your friends and family are all voting, please spread the word... they have friends who need a reminder, too.',
};

export function getSharePrompt(): string {
  let messageId = localStorage['m1'];
  if (!messageId) {
    const messageNum = Math.floor(Math.random() * 3);
    messageId = MESSAGE_IDS[messageNum];
    localStorage['m1'] = messageId;
  }
  Analytics.setUserProperties({shareprompt: messageId});
  const countFrom = new Date('2020-10-10 00:00:00 PST');
  const count = Math.round((Date.now() - countFrom) / (1000 * 24 * 12));
  return MESSAGES[messageId].replace('NNN', count.toLocaleString());
}

export function getShowDaysLeft(): boolean {
  if (localStorage['sdl']) {
    Analytics.setUserProperties({
      showdaysleft: String(localStorage['sdl'] == 'true'),
    });
    return localStorage['sdl'] == 'true';
  } else {
    const showDaysLeft = Math.floor(Math.random() * 2) == 1;
    localStorage['sdl'] = String(showDaysLeft);
    Analytics.setUserProperties({showdaysleft: String(showDaysLeft)});
    return showDaysLeft;
  }
}

export function getPlanView(): string {
  const planview = localStorage['pv']
    ? localStorage['pv']
    : Math.floor(Math.random() * 20) == 1
    ? 'vote'
    : 'plan';
  Analytics.setUserProperties({
    planview: String(planview),
  });
  return planview;
}
