// @flow

import * as React from 'react';

type EffectCallback = () => any;

export function onceOnLoad(fn: EffectCallback) {
  React.useEffect(fn, []);
}

export function onLoad(fn: EffectCallback) {
  React.useEffect(fn);
}
