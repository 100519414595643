// @flow

import {getChannel} from '../Dev';
import * as Analytics from 'expo-firebase-analytics';
import {Navigator} from '@react-navigation/native';
import {siteEntryType} from '../SpreadTheVote';

export async function logEvent(name: string) {
  const channel = getChannel();

  try {
    const prefix = channel == 'prod' ? '' : 'test_';
    const eventName = prefix + name;
    if (channel !== 'prod') {
      console.log('Analytics event,', eventName);
    }
    if (channel !== 'dev') {
      await Analytics.logEvent(eventName);
    }
  } catch (e) {
    if (channel !== 'prod') {
      console.log(e);
    }
  }
}

// For React Navigation
let lastRouteName;

// $FlowFixMe
export function logView(navigator: Navigator) {
  if (lastRouteName == null) {
    logEvent('enter_' + siteEntryType());
  }

  const routeName = navigator.getCurrentRoute().name;

  if (routeName != lastRouteName) {
    lastRouteName = routeName;
    logEvent('view_' + routeName.toLowerCase());
  }
}
