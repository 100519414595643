// @flow
import * as React from 'react';
import {View, Text} from 'react-native';
import {TextInput, useTheme} from 'react-native-paper';
import {type Screen} from './lib/Screen';
import BigButton from './BigButton';
import {BodyText} from './Styles';
import {useAccount} from './lib/Auth';
import {VoteApi} from './Api';

const Check = (props: {children?: React.Node}) => {
  const theme = useTheme();
  const colorStyle = {color: theme.colors.primary};

  return (
    <View style={S.checkRow}>
      <Text style={[S.check, colorStyle]}>{'\u2611'}</Text>
      <Text style={[S.checkText, colorStyle]}>{props.children}</Text>
    </View>
  );
};

const Soon: Screen<{}> = () => {
  const user = useAccount();
  const theme = useTheme();
  const [email, setEmail] = React.useState('');
  const [requested, setRequested] = React.useState(false);

  async function requestInvite() {
    if (user) {
      await VoteApi.requestInvite(user.id, email);
      setRequested(true);
    }
  }

  if (requested) {
    return (
      <View style={S.page}>
        <Text style={S.title(theme)}>Thanks!</Text>
        <BodyText top={12} style={{textAlign: 'center'}}>
          Your email is now on the invite list.
        </BodyText>
      </View>
    );
  }

  return (
    <View style={S.page}>
      <View>
        <Text style={S.title(theme)}>Spread the Vote ♥</Text>
        <Text style={S.belowTitle}>Do your part for democracy</Text>
      </View>
      <View style={{height: 16}} />
      <Check>Remind people to vote !</Check>
      <Check>Let people know you're voting !!</Check>
      <Check>Pass it on !!!</Check>
      <View style={{height: 12}} />
      <BodyText style={{textAlign: 'center'}}>
        We're making it easy to spread the vote via texts, email, Facebook and
        Twitter!
      </BodyText>
      <BodyText top={12} style={{textAlign: 'center'}}>
        Spread the Vote is currently beta testing - enter your email to request
        an invite.
      </BodyText>

      <View style={{height: 18}} />
      <TextInput
        label="Email"
        value={email}
        onChangeText={(text) => setEmail(text)}
      />
      <View style={{height: 18}} />
      <BigButton label="Request Invitation" onPress={requestInvite} />

      <View style={{height: 120}} />
    </View>
  );
};

const S = {
  title: (theme) => ({
    fontSize: 36,
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.colors.primary,
    fontFamily: 'RobotoSlab_700Bold',
    fontVariant: ['small-caps'],
  }),
  belowTitle: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: 18,
    marginTop: 2,
    marginHorizontal: 24,
  },
  page: {
    flex: 1,
    paddingHorizontal: 24,
    maxWidth: 600,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  checkRow: {flexDirection: 'row', alignItems: 'center', marginBottom: 4},
  check: {fontWeight: 'bold', fontSize: 30, marginRight: 14},
  checkText: {
    flexWrap: 'wrap',
    flexShrink: 1,
    fontSize: 20,
    fontWeight: 'bold',
  },
  center: {
    alignSelf: 'center',
  },
};

Soon.id = 'Soon';
Soon.layout = 'full';

export default Soon;
