//@flow
import {useNavigationState} from '@react-navigation/native';
import * as React from 'react';
import {BottomNavigation} from 'react-native-paper';
import {useNav} from './lib/Nav';
import Vote from './Vote';
import Share from './Share';
import {View} from 'react-native';

export function useCurrentRoute(): string {
  return useNavigationState((state) => {
    const routes = state.routes;
    return routes[routes.length - 1].name;
  });
}

const BottomTabs = (): React.Node => {
  const nav = useNav();
  const route = useCurrentRoute();

  const routes = [
    {
      key: 'share',
      title: 'Spread the Vote',
      icon: 'heart-outline',
      component: Share,
    },
    {
      key: 'info',
      title: 'Plan Your Vote',
      icon: 'information-outline',
      component: Vote,
    },
  ];

  const indexes = {Share: 0, Vote: 1};

  return (
    <View>
      <BottomNavigation
        navigationState={{index: indexes[route], routes}}
        activeColor="#202868"
        inactiveColor="#4060C0"
        style={{minHeight: 56}}
        barStyle={{
          backgroundColor: '#FFF',
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderColor: '#888',
        }}
        renderScene={() => <></>}
        onIndexChange={(index) => {
          nav.push(routes[index].component);
        }}
      />
    </View>
  );
};
export default BottomTabs;
