// @flow

import * as firebase from 'firebase/app';

export type FirebaseConfig = {
  projectId: string,
  apiKey: string,
  measurementId?: string,
  appId?: string,
};

function fullConfig(cfg: FirebaseConfig) {
  const {apiKey, projectId, measurementId, appId} = cfg;
  return {
    apiKey,
    authDomain: projectId + '.firebaseapp.com',
    databaseUrl: 'https://' + projectId + '.firebaseio.com',
    projectId,
    storageBucket: projectId + '.appspot.com',
    measurementId,
    appId,
  };
}

let initialized = false;

export function initFirebase(cfg: FirebaseConfig) {
  if (!initialized) {
    initialized = true;
    firebase.initializeApp(fullConfig(cfg));
    firebase.auth();
  }
}
