// @flow

import * as React from 'react';
import {Text, View} from 'react-native';
import {type Screen} from './lib/Screen';
import {useAccount} from './lib/Auth';
import {useAuth} from './lib/Auth';
import {Button} from 'react-native-paper';

const Account: Screen<{}> = () => {
  const user = useAccount();
  const auth = useAuth();

  return (
    <View style={{padding: 40}}>
      <Button onPress={() => auth.tryLogin()}>Log In?</Button>
      <View style={{height: 20}} />
      <Button onPress={() => auth.logout()}>Log Out</Button>
      <View style={{height: 20}} />
      <Text>{JSON.stringify(user)}</Text>
    </View>
  );
};

Account.id = 'Account';
Account.title = 'My Account';

export default Account;
