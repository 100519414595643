// @flow
/* global require */
import * as React from 'react';
import {type Screen} from './lib/Screen';
import {View, ScrollView, Image} from 'react-native';
import {BulletText} from './Styles';
import {useAccount} from './lib/Auth';
import {useAuth} from './lib/Auth';
import {VoteApi} from './Api';
import {logEvent} from './lib/Logging';
// $FlowFixMe
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {TouchableRipple} from 'react-native-paper';
import {getUserData, setUserData} from './lib/Firestore';
import type {ViewStyleProp} from 'react-native/Libraries/StyleSheet/StyleSheet';

type VotePlan = {
  registered: boolean,
  where: ?string,
  voted: boolean,
};

type Props = {
  async: {
    votes: number,
    state: string,
    plan: VotePlan,
  },
};

const ChecklistItem = (props: {
  set: boolean,
  text: string,
  onPress: () => void,
}) => {
  const {set, text, onPress} = props;

  // $FlowFixMe
  const rowStyle: ViewStyleProp = S.checkrow;

  return (
    <TouchableRipple onPress={onPress}>
      <View style={rowStyle}>
        <View>
          <Icon name="checkbox-blank-outline" size={48} color="#202868" />
          {set && (
            <Icon
              name="heart"
              size={30}
              color="#B00"
              style={{position: 'absolute', left: 9, top: 9}}
            />
          )}
        </View>
        <View style={{width: 11}} />
        <BulletText>{text}</BulletText>
      </View>
    </TouchableRipple>
  );
};

const MyPlan: Screen<Props> = (props: Props) => {
  const [plan, setPlan] = React.useState(props.async.plan);
  const {registered, where, voted} = plan;
  const [, setDrawAttention] = React.useState();
  const [voteCount, setVoteCount] = React.useState(props.async.votes);
  const user = useAccount();

  function randomVoters() {
    if (Math.random() > 0.9) {
      setVoteCount(voteCount + 1);
    }
  }

  React.useEffect(() => {
    const interval = setInterval(randomVoters, 3000);
    return () => clearInterval(interval);
  });

  React.useEffect(() => {
    if (user) {
      VoteApi.recordEvent(user.id, 'planning');
    }
  }, [user]);

  function updatePlan(newValues: $Shape<VotePlan>) {
    const newPlan = {...plan, ...newValues};
    if (user) {
      setUserData(user.id, 'plan', newPlan);
    }
    setPlan(newPlan);
  }

  function toggleVote() {
    (async () => {
      if (registered) {
        logEvent('action_undo_voting');
      } else {
        logEvent('action_voting');
      }
      if (user) {
        await Promise.all([
          VoteApi.setVoting(user.id, !registered),
          registered
            ? VoteApi.decrementVoteCount()
            : VoteApi.incrementVoteCount(),
          VoteApi.recordEvent(user.id, 'voting'),
        ]);
        const delta = registered ? -1 : 1;
        setVoteCount(voteCount + delta);
      }
      updatePlan({registered: !registered});
      if (!registered) {
        setDrawAttention(true);
      }
    })();
  }

  const img = require('../assets/voteheart.png');

  return (
    <View style={S.container}>
      <ScrollView
        contentContainerStyle={{
          padding: 32,
          paddingTop: 26,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <View>
            <ChecklistItem
              set={registered}
              text="I'm Registered"
              onPress={() => toggleVote()}
            />
            {!registered && (
              <View style={{flexDirection: 'row'}}>
                <View style={{width: 56}} />
              </View>
            )}
            {where != 'inperson' && (
              <ChecklistItem
                set={where == 'bymail'}
                text={"I'm Voting by Mail"}
                onPress={() => updatePlan({where: where ? null : 'bymail'})}
              />
            )}
            {where != 'bymail' && (
              <ChecklistItem
                set={where == 'inperson'}
                text="I'm Voting in Person"
                onPress={() => updatePlan({where: where ? null : 'inperson'})}
              />
            )}
            <ChecklistItem
              set={voted}
              text="I Voted !!!"
              onPress={() => updatePlan({voted: !voted})}
            />
          </View>
          <Image
            style={{width: 200, height: 200, resizeMode: 'contain'}}
            source={{uri: img}}
          />
        </View>
      </ScrollView>
    </View>
  );
};

const S = {
  button: {
    width: 280,
    alignSelf: 'center',
    marginHorizontal: 12,
  },
  bigCount: {
    fontFamily: 'RobotoSlab_700Bold',
    fontSize: 40,
    textAlign: 'center',
  },
  container: {
    flex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    backgroundColor: '#FFF',
  },
  checktext: {
    fontSize: 30,
    fontWeight: 'bold',
  },
  checkrow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: -4,
    cursor: 'pointer',
  },
};

MyPlan.id = 'MyPlan';
MyPlan.title = 'My Vote Plan';

const NO_STATUS = {
  registered: false,
  where: null,
  voted: false,
};

MyPlan.load = async () => {
  // Ensure user is loaded by awaiting call
  const user = await useAuth().getUser();

  const getVoting = async () =>
    user ? await VoteApi.isVoting(user.id) : false;

  const getState = async () => {
    const result = await VoteApi.geocode();
    if (result.country !== 'US') {
      return 'PA';
    }
    return result.region || 'PA';
  };

  const getPlan = async () => {
    const oldVoting = await getVoting();

    if (!user) {
      return NO_STATUS;
    }
    const result = (await getUserData(user.id, 'plan')) || {...NO_STATUS};
    result.registered = result.registered || oldVoting;

    return result;
  };

  const [votes, state, plan] = await Promise.all([
    VoteApi.getVoteCount(),
    getState(),
    getPlan(),
  ]);

  return {votes, state, plan};
};

export default MyPlan;
