// @flow

import * as React from 'react';
import type {TextStyleProp} from 'react-native/Libraries/StyleSheet/StyleSheet';
import {Subheading, Text} from 'react-native-paper';

type StyledTextProps = {
  children: React.Node,
  style?: TextStyleProp,
  top?: number,
};

type StyledText = React.ComponentType<StyledTextProps>;

function styledText(
  Component: React.ComponentType<any>,
  textStyle: TextStyleProp
): StyledText {
  const TextComponent = (props: StyledTextProps): React.Node => {
    const {style, children, top} = props;
    const topStyle = top ? {paddingTop: top} : null;
    return (
      <Component style={[textStyle, topStyle, style]}>{children}</Component>
    );
  };
  return TextComponent;
}

export const SectionTitle: StyledText = styledText(Subheading, {
  fontSize: 20,
  fontWeight: 'bold',
});

export const BodyText: StyledText = styledText(Text, {fontSize: 18});

export const MiddleText: StyledText = styledText(Text, {
  fontSize: 18,
  textAlign: 'center',
});

export const BulletText: StyledText = styledText(Text, {
  fontSize: 24,
  color: '#202868',
  fontFamily: 'RobotoSlab_700Bold',
  fontVariant: ['small-caps'],
});

export const InfoText: StyledText = styledText(Text, {
  fontSize: 18,
  fontStyle: 'italic',
  textAlign: 'center',
});
