import {useNavigation} from '@react-navigation/native';
import * as React from 'react';
import {type StackNavigator} from '@react-navigation/stack';
import {Text, View} from 'react-native';
import ActionMenu from './ActionMenu';
import {type Screen} from './Screen';
import {IconButton} from 'react-native-paper';
import BasicFrame from './BasicFrame';
import {load} from './Async';

type Nav = {
  push: (route: string) => void,
  replace: (route: string) => void,
  pop: () => void,
  canGoBack: () => Boolean,
  goBack: () => void,
};

export function useNav(): Nav {
  const navigation = useNavigation();

  return {
    push: (screen) => navigation.push(screen.id),
    replace: (screen) => navigation.replace(screen.id),
    pop: () => navigation.pop(),
    goBack: () => navigation.goBack(),
    canGoBack: () => navigation.canGoBack(),
  };
}

const Header = (props: {screen: Screen<any>, actions: () => Action[]}) => {
  const {screen} = props;
  const nav = useNav();
  const title = screen.title || screen.id;
  const backOk = !!screen.back;
  const actions = (screen.actions || []).concat(props.actions());

  function goBack() {
    nav.goBack();
  }

  const canBack = nav.canGoBack() && backOk;

  const back = canBack ? (
    <IconButton icon="chevron-left" color="#FFF" size={24} onPress={goBack} />
  ) : (
    <View style={{width: 40}} />
  );

  return (
    <View style={S.header}>
      {back}
      <Text style={S.headerText}>{title.toUpperCase()}</Text>
      {actions.length > 0 ? (
        <ActionMenu actions={actions} />
      ) : (
        <View style={{width: 40}} />
      )}
    </View>
  );
};

function headerFor(screen: Screen<any>, actions: () => Action[]) {
  const HeaderFor = (props) => (
    <Header screen={screen} {...props} actions={actions} />
  );
  return HeaderFor;
}

function wrap(
  Component: React.ComponentType<{}>,
  Wrapper: React.ComponentType<{}>
) {
  const wrapped = (props) => {
    return (
      <Wrapper>
        <Component {...props} />
      </Wrapper>
    );
  };
  return wrapped;
}

export function stackScreensFor(
  Stack: StackNavigator,
  screens: Screen[],
  actions: () => Action[],
  frame: React.ComponentType<any> = BasicFrame
): React.Node[] {
  return screens.map((screen) => {
    const opts = {
      header: headerFor(screen, actions),
      headerShown: screen.layout !== 'full',
      animationEnabled: true,
      gestureDirection:
        screen.id == 'Vote' ? 'horizontal' : 'horizontal-inverted',
    };

    const loaded = screen.load ? load(screen) : screen;

    const wrapped = wrap(loaded, frame);

    return (
      <Stack.Screen
        key={screen.id}
        name={screen.id}
        component={wrapped}
        options={opts}
      />
    );
  });
}

const S = {
  header: {
    height: 72,
    flexDirection: 'row',
    left: 0,
    right: 0,
    backgroundColor: '#202868',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  headerText: {
    color: '#FFF',
    fontSize: 24,
    fontWeight: 'bold',
    fontFamily: 'RobotoSlab_700Bold',
    fontVariant: ['small-caps'],
  },
};
