// @flow

import 'firebase/auth';

import * as firebase from 'firebase/app';
import {type User, type AuthApi} from './Auth';
import {logInUi} from './FirebaseLoginUi';

function initialLoad(): Promise<void> {
  firebase.auth();

  let loadResolver, loadRejecter;
  let loaded = false;

  const loadingPromse = new Promise((resolve, reject) => {
    loadResolver = resolve;
    loadRejecter = reject;
  });

  // Should reject at some point on failure...
  setTimeout(() => {
    if (!loaded) {
      loadRejecter && loadRejecter(new Error('Timed out loading auth'));
      loaded = true;
    }
  }, 60000);

  // $FlowFixMe
  firebase.auth().onAuthStateChanged(() => {
    if (!loaded) {
      loaded = true;
      if (firebase.auth().currentUser) {
        loadResolver && loadResolver();
      } else {
        firebase
          .auth()
          .signInAnonymously()
          .finally(() => loadResolver && loadResolver());
      }
    }
  });

  return loadingPromse;
}

export function firebaseAuthApi(): AuthApi {
  const loadPromise = initialLoad();

  async function logout() {
    await firebase.auth().signOut();
  }

  async function getUser() {
    await loadPromise;
    return convert(firebase.auth().currentUser);
  }

  async function tryLogin(showPrivacy?: () => void) {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    await logInUi(showPrivacy);
    return await getUser();
  }

  return {
    logout,
    getUser,
    tryLogin,
  };
}

// $FlowFixMe
function convert(from: ?firebase.User): ?User {
  if (from == null) {
    return null;
  }
  // Probably should merge this...
  const provider = from.providerData?.[0];

  return {
    id: from.uid,
    name: from.displayName ?? provider?.displayName,
    email: from.email ?? provider?.email,
    phone: from.phoneNumber ?? provider?.phoneNumber,
    pic: from.photoURL ?? provider?.photoURL,
    type: provider?.providerId ?? 'anon',
  };
}
