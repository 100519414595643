// @flow

import * as React from 'react';
import {type Screen} from './lib/Screen';
import {View, ScrollView} from 'react-native';
import {Text} from 'react-native-paper';
import {useNav} from './lib/Nav';
import BigButton from './BigButton';
import {InfoText} from './Styles';
import Share from './Share';
import {Animated} from 'react-native';
import {useFadeIn} from './lib/Animation';
import Info from './Info';
import BottomNav from './BottomNav';
import {MiddleText} from './Styles';
import {useAccount} from './lib/Auth';
import {useAuth} from './lib/Auth';
import {VoteApi} from './Api';
import {logEvent} from './lib/Logging';

type Props = {
  async: {
    voting: boolean,
    votes: number,
    state: string,
  },
};

const Vote: Screen<Props> = (props: Props) => {
  const [voting, setVoting] = React.useState(props.async.voting);
  const [drawAttention, setDrawAttention] = React.useState();
  const [voteCount, setVoteCount] = React.useState(props.async.votes);
  const initialState = props.async.state.toLowerCase();
  const user = useAccount();

  function randomVoters() {
    if (Math.random() > 0.9) {
      setVoteCount(voteCount + 1);
    }
  }

  React.useEffect(() => {
    const interval = setInterval(randomVoters, 3000);
    return () => clearInterval(interval);
  });

  React.useEffect(() => {
    if (user) {
      VoteApi.recordEvent(user.id, 'planning');
    }
  }, [user]);
  const prefix = voting ? '✓\u00A0\u00A0\u00A0' : '';
  const label = prefix + "I'm  Going to Vote!";
  const buttonStyle = voting ? {backgroundColor: '#D0D0DA'} : null;

  function toggleVote() {
    (async () => {
      if (voting) {
        logEvent('action_undo_voting');
      } else {
        logEvent('action_voting');
      }
      if (user) {
        await Promise.all([
          VoteApi.setVoting(user.id, !voting),
          voting ? VoteApi.decrementVoteCount() : VoteApi.incrementVoteCount(),
          VoteApi.recordEvent(user.id, 'voting'),
        ]);
        const delta = voting ? -1 : 1;
        setVoteCount(voteCount + delta);
        setVoting(!voting);
      }
      if (!voting) {
        setDrawAttention(true);
      }
    })();
  }

  return (
    <View style={S.container}>
      <ScrollView
        contentContainerStyle={{
          padding: 32,
        }}
      >
        <View style={{maxWidth: 400, alignSelf: 'center'}}>
          <MiddleText>
            In 2016, 38% of eligible voters didn't vote.{'\n'}
            Let's add 200,000 voters this year!
          </MiddleText>
        </View>

        <View style={{marginTop: 24, alignSelf: 'center'}}>
          <BigButton label={label} style={buttonStyle} onPress={toggleVote} />
        </View>
        <View style={{height: 14}} />
        <Text style={S.bigCount}>{voteCount.toLocaleString()} Voters</Text>
        <InfoText>...and counting</InfoText>
        <View style={{height: 16}} />
        <Info initialState={initialState} />
        {false && <YourTurn />}
      </ScrollView>
      <BottomNav drawAttention={drawAttention} planView="vote" />
    </View>
  );
};

const YourTurn = () => {
  const nav = useNav();
  const [fadeIn, animation] = useFadeIn(2000);

  animation.start();

  return (
    <Animated.View style={fadeIn}>
      <View>
        <BigButton
          style={{alignSelf: 'center'}}
          labelStyle={{fontSize: 32}}
          uppercase={false}
          label="Your Turn Now ♥"
          onPress={() => nav.push(Share)}
        />
      </View>
    </Animated.View>
  );
};

const S = {
  button: {
    width: 280,
    alignSelf: 'center',
    marginHorizontal: 12,
  },
  bigCount: {
    fontFamily: 'RobotoSlab_700Bold',
    fontSize: 40,
    textAlign: 'center',
  },
  container: {
    flex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
  },
};

Vote.id = 'Vote';
Vote.title = 'Plan Your Vote';

Vote.load = async () => {
  // Ensure user is loaded by awaiting call
  const user = await useAuth().getUser();

  const getVoting = async () =>
    user ? await VoteApi.isVoting(user.id) : false;

  const getState = async () => {
    const result = await VoteApi.geocode();
    if (result.country !== 'US') {
      return 'PA';
    }
    return result.region || 'PA';
  };

  const [voting, votes, state] = await Promise.all([
    getVoting(),
    VoteApi.getVoteCount(),
    getState(),
  ]);

  return {voting, votes, state};
};

export default Vote;
