// @flow

import 'react-native-gesture-handler';

import * as React from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import SpreadTheVote from './app/SpreadTheVote';
import {setSiteEntryType} from './app/SpreadTheVote';

function planOverride() {
  if (Platform.OS === 'web') {
    if (document.location.href.toLowerCase().indexOf('/unlock') != -1) {
      localStorage['plan'] = 'true';
    }
  }
}

function referrerLogic() {
  try {
    if (Platform.OS === 'web') {
      const path = document.location.pathname;
      const match = path.match(/from\/([a-z]?[0-9]+)$/);
      if (match) {
        if (!match[1].startsWith('u') && !match[1].startsWith('g')) {
          setSiteEntryType('share');
        }
        let refs = JSON.parse(localStorage.getItem('refs') || '{}');
        refs[match[1]] = 1;
        localStorage['refs'] = JSON.stringify(refs);
      }
    }
  } catch (e) {
    //
  }
}

export default function App(): React.Node {
  planOverride();
  referrerLogic();

  return (
    <View style={styles.page}>
      <View style={styles.container}>
        <SpreadTheVote />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
    justifyContent: 'center',
    maxWidth: 640,
    overflow: 'hidden',
  },
  page: {
    backgroundColor: '#000',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
  },
});
