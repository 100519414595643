// @flow

let time = Date.now();

class Promised<C> extends Promise<C> {
  promise: Promise<?C>;
  done: boolean = false;
  value: C;
  error: ?any = null;
  fulfilled: boolean = false;

  // OK - promise.then() can (I think) call your original constructor
  // to generate a new promise, so believe we need a constructor that
  // has the shape of the promise constructor...
  constructor(from: Promise<C> | Function | C) {
    let resolver;
    let rejecter;

    super((resolve, reject) => {
      resolver = resolve;
      rejecter = reject;
    });

    let promise: Promise<C>;
    if (typeof from === 'function') {
      // Note: This means we can't promise a function yet...
      promise = new Promise((from: Function));
    } else if (from instanceof Promise) {
      promise = from;
    } else {
      // From is the value for an immediate Promised.
      promise = Promise.resolve(from);
      this.done = true;
      this.fulfilled = true;
      this.value = from;
    }

    this.promise = promise;

    this.promise
      .then((value: C) => {
        this.value = value;
        this.fulfilled = true;
        this.done = true;
        resolver(value);
      })
      .catch((error) => {
        this.error = error;
        this.done = true;
        rejecter(error);
      });
  }

  // Throws when promise isn't resolved yet
  // Throws when promise rejected
  getValue(): C {
    if (!this.done) {
      throw this;
    }

    if (!this.fulfilled) {
      throw this.error;
    }

    return this.value;
  }

  isDone(): boolean {
    return this.done;
  }

  getError(): any {
    return this.error;
  }
}

export default Promised;
