// @flow

import * as React from 'react';
import {Button, useTheme} from 'react-native-paper';
import type {ViewStyleProp} from 'react-native/Libraries/StyleSheet/StyleSheet';

const BigButton = (props: {
  label: string,
  icon?: ?string,
  style?: ViewStyleProp,
  onPress: () => void | Promise<void>,
}): React.Node => {
  const {label, icon, style, onPress} = props;
  const defaultTheme = useTheme();

  const theme = {...defaultTheme, roundness: 12};

  return (
    <Button
      icon={icon}
      theme={theme}
      style={[S.button, style]}
      labelStyle={S.buttonText}
      mode="outlined"
      uppercase={false}
      onPress={onPress}
    >
      {label}
    </Button>
  );
};

const S = {
  button: {
    alignSelf: 'center',
    marginHorizontal: 12,
    backgroundColor: '#FFF',
  },
  buttonText: {
    fontSize: 22,
    paddingVertical: 4,
    paddingHorizontal: 16,
    fontFamily: 'RobotoSlab_700Bold',
    fontVariant: ['small-caps'],
  },
};

export default BigButton;
