// @flow

import * as React from 'react';

export type Handler = () => Promise<void> | void;
export type HandlerRef = {current?: ?Handler};

export type Action = {
  id: string,
  icon: string,
  label: string,
  handle: () => Handler,
};

export function useAction(action: Action): Handler {
  const handler = action.handle();
  return handler;
}

// Useful for variable # of actions displayed
// As useContext calls needs to be same per component render
export function actionHandlerComponent(
  action: Action
): React$ComponentType<{handler: HandlerRef}> {
  const handlerComponent = (props: {handler: HandlerRef}) => {
    props.handler.current = useAction(action);
    return <></>;
  };
  return handlerComponent;
}
