// @flow

import * as React from 'react';
import {View, Text} from 'react-native';
import {useNav} from './lib/Nav';
import {InfoText} from './Styles';
import {VoteApi} from './Api';
import {useAuth} from './lib/Auth';
import {Button} from 'react-native-paper';
import {useAccount} from './lib/Auth';
import Privacy from './Privacy';

const Reach = (): React.Node => {
  const user = useAccount();
  const auth = useAuth();
  const nav = useNav();
  const loggedIn = user && user.type !== 'anon';
  const [reachStr, setReachStr] = React.useState('...');

  async function loadReach() {
    if (user) {
      const reach = await VoteApi.getReach(user.id);
      setReachStr(reach.voteReach.toLocaleString());
    }
  }
  React.useEffect(() => {
    loadReach();
  }, [user]);

  async function logIn() {
    await auth.tryLogin(() => nav.push(Privacy));
  }

  async function logout() {
    await auth.logout();
  }

  return (
    <>
      <View style={{height: 20}} />
      <Text style={[S.bigCount, {fontSize: 22}]}>Your Vote Score</Text>
      <InfoText top={4}>How many people you have reached</InfoText>
      <Text style={S.bigCount}>{reachStr}</Text>
      {!loggedIn && (
        <View>
          <Button
            style={{marginTop: 12, alignSelf: 'center'}}
            mode="outlined"
            onPress={logIn}
          >
            Log In to Save
          </Button>
        </View>
      )}
      {loggedIn && (
        <View>
          <Button
            style={{marginTop: 12, alignSelf: 'center'}}
            mode="outlined"
            onPress={logout}
          >
            Log Out
          </Button>
          <View style={{height: 8}} />
          <Text style={{textAlign: 'center', fontSize: 14}}>
            Logged In as {user?.name}
          </Text>
        </View>
      )}
      <View style={{height: 12}} />
    </>
  );
};

const S = {
  bigCount: {
    fontFamily: 'RobotoSlab_700Bold',
    fontSize: 40,
    textAlign: 'center',
  },
};

export default Reach;
