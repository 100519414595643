// @flow
import * as React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {Button, Card, RadioButton, Text} from 'react-native-paper';
import {useFadeIn} from './lib/Animation';
import {Animated} from 'react-native';
import {onceOnLoad} from './lib/Lifecycle';

import {type Screen} from './lib/Screen';
import {useNav} from './lib/Nav';
import {ScrollView} from 'react-native';
import {SectionTitle, BodyText} from './Styles';
import {facebook, FOOTER, isDesktopWeb, share, sms} from './lib/Links';
import {twitter} from './lib/Links';
import {email} from './lib/Links';
import {logEvent} from './lib/Logging';
import {VoteApi} from './Api';
import {useAuth} from './lib/Auth';
import {useAccount} from './lib/Auth';
import Share from './Share';

const MESSAGES: Array<(string | null)[]> = [
  [
    'Mail-in voting has already started',
    'Mail-in voting has already started! Send in your ballot today.',
  ],
  [
    'It’s not too late to register to vote',
    'It’s not too late to register to vote!',
  ],
  [
    'Do your part in getting out the vote!',
    '🗳️ Do your part in getting out the vote - remind your friends to register and vote!',
  ],
  ['Compose your own message', '[your message here]'],
];

function msgFor(row: (string | null)[]): string {
  return row[1] ?? row[0] ?? '';
}

const MessageRow = (props: {
  msg: (string | null)[],
  on: boolean,
  onPress: () => void,
}) => {
  const {msg, on, onPress} = props;
  const status = on ? 'checked' : 'unchecked';
  return (
    <TouchableOpacity onPress={onPress} activeOpacity={0.2}>
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <RadioButton value={msg[0]} status={status} onPress={onPress} />
        <View style={{width: 12}} />
        <Text style={{fontSize: 15}}>{msg[0]}</Text>
      </View>
    </TouchableOpacity>
  );
};

type Sharer = {icon: string, label: string, share: (string, string) => void};

const SHARERS: {[string]: Sharer} = {
  facebook: {icon: 'facebook', label: 'Facebook', share: facebook},
  twitter: {icon: 'twitter', label: 'Twitter', share: twitter},
  email: {icon: 'email', label: 'Email', share: email},
  sms: {icon: 'message-outline', label: 'SMS', share: sms},
};

const ShareButton = (props: {type: string, msg: string, from: string}) => {
  const {type, msg, from} = props;
  const sharer = SHARERS[type];
  const user = useAccount();
  if (!sharer) {
    return <></>;
  }

  function share() {
    logEvent('action_share_' + type);
    if (user) {
      VoteApi.recordEvent(user.id, 'sharing');
    }
    sharer.share(msg, from);
  }

  return (
    <Button
      icon={sharer.icon}
      mode="outlined"
      style={{backgroundColor: '#FFF', marginBottom: 12, marginRight: 12}}
      onPress={share}
    >
      {sharer.label}
    </Button>
  );
};

type Props = {
  async: {
    from: string,
  },
};

const Send: Screen<Props> = (props: Props) => {
  const [msgRow, setMsgRow] = React.useState(MESSAGES[0]);
  const [fadeIn, animation] = useFadeIn(2000);
  const {from} = props.async;
  const user = useAccount();
  const nav = useNav();

  onceOnLoad(() => animation.start());

  function shareSheet() {
    share(msgFor(msgRow), from);
    logEvent('action_share_sheet');
    if (user) {
      VoteApi.recordEvent(user.id, 'sharing');
    }
  }

  function cancel() {
    if (nav.canGoBack()) {
      nav.goBack();
    } else {
      nav.replace(Share);
    }
  }

  return (
    <View style={S.container}>
      <ScrollView contentContainerStyle={S.scroll}>
        <Animated.View style={fadeIn}>
          <SectionTitle>Your message</SectionTitle>
          <View style={{height: 12}} />
          <Card elevation={6} style={{shadowOffset: {width: 4, height: 4}}}>
            <Card.Content>
              <BodyText>{msgFor(msgRow)}</BodyText>
              <View style={{height: 12}} />
              <Text style={{fontSize: 13}}>{FOOTER}</Text>
            </Card.Content>
          </Card>
          <View style={{height: 12}} />
          <View style={{marginHorizontal: 12}}>
            {MESSAGES.map((cur, idx) => (
              <MessageRow
                on={cur === msgRow}
                msg={cur}
                onPress={() => setMsgRow(cur)}
                key={idx}
              />
            ))}
            <View style={{height: 8}} />
            <Text style={{marginLeft: 6, fontSize: 15, fontStyle: 'italic'}}>
              You'll be able to edit the message before you send in the app you
              choose below
            </Text>
          </View>
          <View style={{height: 16}} />
          <SectionTitle>Send with</SectionTitle>
          <View style={{height: 12}} />
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
            }}
          >
            <ShareButton type="twitter" msg={msgFor(msgRow)} from={from} />
            <ShareButton type="facebook" msg={msgFor(msgRow)} from={from} />
            <ShareButton type="email" msg={msgFor(msgRow)} from={from} />
            {!isDesktopWeb() && (
              <ShareButton type="sms" msg={msgFor(msgRow)} from={from} />
            )}

            {!isDesktopWeb() && (
              <Button onPress={shareSheet} style={S.share}>
                More...
              </Button>
            )}
          </View>

          <View style={{height: 12}} />
          <Button onPress={cancel}>Cancel</Button>
        </Animated.View>
      </ScrollView>
    </View>
  );
};

const S = {
  scroll: {
    flex: 1,
    alignItems: 'stretch',
    padding: 24,
    flexGrow: 1,
  },
  container: {
    flex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
  },
  share: {marginBottom: 12, marginRight: 12},
};

Send.id = 'Send';
Send.title = 'Pick Message & Send';
Send.back = true;

Send.load = async () => {
  // Ensure user is loaded by awaiting call
  const user = await useAuth().getUser();

  if (!user) {
    return {from: ''};
  }
  return {
    from: await VoteApi.getFromId(user.id),
  };
};

export default Send;
