// @flow
import * as React from 'react';
import {type Screen} from './lib/Screen';
import {Platform} from 'react-native';
import {WebView} from 'react-native-web-webview';

const Privacy: Screen<{}> = () => {
  return (
    <>
      {Platform.OS === 'web' && (
        <WebView
          style={{
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
          source={{
            uri:
              'https://www.privacypolicies.com/live/850ad3f2-0feb-4ab5-b0df-aa6cbd19ea36',
          }}
        />
      )}
    </>
  );
};

Privacy.id = 'Privacy';
Privacy.title = 'Privacy policy';
Privacy.back = true;

export default Privacy;
