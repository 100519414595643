// @flow
import {Linking, Platform} from 'react-native';
import {getChannel} from '../Dev';

export const FOOTER_MSG: string = 'Spread the Vote ♥';
export const URL: string =
  getChannel() == 'prod'
    ? 'https://spreadthevote.net'
    : 'https://testthevote.web.app';
export const FOOTER: string = FOOTER_MSG + '\n' + URL;

export function newBrowserWindow(url: string) {
  if (Platform.OS === 'web') {
    window.open(url, '_blank');
    return;
  }
  Linking.openURL(url);
}

export function share(msg: string, from: string, plan?: string) {
  const shareInfo = {
    url: sendUrl('p', from, plan),
    text: msg + '\n\n' + FOOTER_MSG,
  };

  if (navigator && navigator.share) {
    navigator.share(shareInfo);
  }
}

const MAC_PLATFORMS = [
  'Macintosh',
  'MacIntel',
  'MacPPC',
  'Mac68K',
  'iPhone',
  'iPad',
  'iPod',
];

export function isApple(): boolean {
  return (
    Platform.OS === 'ios' || MAC_PLATFORMS.indexOf(navigator?.platform) != -1
  );
}

export function isDesktopWeb(): boolean {
  if (!navigator.userAgent) {
    return false;
  }
  return !/android|webos|iphone|ipad|ipod/.test(
    navigator.userAgent.toLowerCase()
  );
}

export function sms(msg: string, from: string, plan?: string) {
  const del = isApple() ? '&' : '?';
  const url =
    'sms:' +
    del +
    'body=' +
    encodeURIComponent(msg + '\n\n' + footer('s', from, plan));
  Linking.openURL(url);
}

export function twitter(msg: string, from: string, plan?: string) {
  console.log(FOOTER_MSG);
  // Note: URL param wasn't working as well, at least on desktop web...
  const url =
    'http://www.twitter.com/intent/tweet?text=' +
    encodeURIComponent(
      msg +
        (plan ? '\n' : '\n\n' + FOOTER_MSG) +
        '\n' +
        sendUrl('t', from, plan)
    );

  newBrowserWindow(url);
}

export function facebook(msg: string, from: string, plan?: string) {
  /*
  const domain = isDesktopWeb() ? 'www.facebook.com' : 'm.facebook.com';
  const url =
    'https://' +
    domain +
    '/sharer/sharer.php?u=' +
    encodeURIComponent(sendUrl('f', from, plan)) +
    '&t=' +
    encodeURIComponent(msg + '\n\n' + FOOTER_MSG);

  newBrowserWindow(url);*/

  const url =
    'https://www.facebook.com/dialog/share?' +
    'app_id=710450163149353' +
    '&display=popup' +
    '&href=' +
    encodeURIComponent(sendUrl('f', from, plan));

  newBrowserWindow(url);
}

export function email(msg: string, from: string, plan?: string) {
  const subject = 'Spread the Vote ♥';
  const url =
    'mailto:?subject=' +
    encodeURIComponent(subject) +
    '&body=' +
    encodeURIComponent(msg + '\n' + footer('e', from, plan));
  newBrowserWindow(url);
}

function footer(src: string, from: string, plan?: string) {
  return (plan ? '' : FOOTER_MSG) + '\n' + sendUrl(src, from, plan);
}

function sendUrl(src: string, from: string, plan?: string) {
  return URL + '/from/' + src + from + (plan ? '?plan=' + plan : '');
}
