// @flow

import * as React from 'react';

import {View, Picker} from 'react-native';
import {Button, Text} from 'react-native-paper';
import type {TextStyleProp} from 'react-native/Libraries/StyleSheet/StyleSheet';
import {BodyText, SectionTitle} from './Styles';
import {newBrowserWindow} from './lib/Links';
import {STATES, BY_STATE} from './States';
import {BY_ABBR} from './States';

/*type VoteInfo = {
  state: string,
  reg_in_person: string,
  reg_by_mail: string,
  reg_received: string,
  reg_online: string,
  poll_finder: string,
};*/

export const Info = (props: {initialState: string}): React.Node => {
  const initialState =
    BY_ABBR[props.initialState.toUpperCase()]?.state || 'Pennsylvania';

  const [state, setState] = React.useState(initialState);
  const info = BY_STATE[state];

  function pollLocations() {
    newBrowserWindow(info.poll_finder);
  }
  function votingInfo() {
    // TODO: Abbreviate
    newBrowserWindow(
      'https://howto.vote/vote/en/' + info.abbr.toLowerCase() + '.html'
    );
  }

  return (
    <View style={{marginTop: 16}}>
      <View style={{flexDirection: 'row'}}>
        <SectionTitle>Voting Info for </SectionTitle>
        <Picker
          style={{
            borderWidth: 0,
            margin: 0,
            padding: 0,
            fontSize: 20,
            backgroundColor: 'rgba(0,0,0,0)',
            fontWeight: 'bold',
            color: 'rgba(33,33,33,0.87)',
            fontFamily: 'Roboto', //theme.fonts.regular.fontFamily,
            letterSpacing: 0.5,
            marginTop: -1,
            marginLeft: 4,
          }}
          selectedValue={state}
          onValueChange={(val) => setState(String(val))}
        >
          {STATES.map((state) => (
            <Picker.Item key={state} label={state} value={state} />
          ))}
        </Picker>
      </View>
      <View style={{height: 12}} />
      <BodyText style={{fontStyle: 'italic'}}>Registration deadlines</BodyText>
      <View style={{height: 12}} />
      <View style={S.justified}>
        <MainText>In person</MainText>
        <MainText>{info.reg_in_person}</MainText>
      </View>
      <View style={S.justified}>
        <MainText>By mail</MainText>
        <MainText>{info.reg_by_mail}</MainText>
      </View>
      <View style={S.justified}>
        <MainText>Online</MainText>
        <MainText>{info.reg_online}</MainText>
      </View>
      <View style={{height: 10}} />
      <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
        <Button
          icon="map-marker-outline"
          mode="outlined"
          onPress={pollLocations}
        >
          poll locations
        </Button>
        <View style={{width: 10}} />
        <Button mode="outlined" onPress={votingInfo}>
          more info
        </Button>
      </View>
    </View>
  );
};

const MainText = (props: {style?: TextStyleProp, children?: React.Node}) => {
  return <Text style={[S.text, props.style]}>{props.children}</Text>;
};

const S = {
  text: {fontSize: 18},
  justified: {
    flexDirection: 'row',
    marginBottom: 12,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export default Info;
