// @flow
import * as React from 'react';
import {View, Text} from 'react-native';
import {Button, useTheme} from 'react-native-paper';
import {useNav} from './lib/Nav';
import {type Screen} from './lib/Screen';
import Share from './Share';

const Check = (props: {children?: React.Node}) => {
  const theme = useTheme();
  const colorStyle = {color: theme.colors.primary};

  return (
    <View style={S.checkRow}>
      <Text style={[S.check, colorStyle]}>{'\u2611'}</Text>
      <Text style={[S.checkText, colorStyle]}>{props.children}</Text>
    </View>
  );
};

const Enter: Screen<{}> = () => {
  const nav = useNav();
  const theme = useTheme();

  return (
    <View style={S.page}>
      <View>
        <Text style={S.title(theme)}>Spread the Vote ♥</Text>
        <Text style={S.belowTitle}>Do your part for democracy</Text>
      </View>
      <View style={{height: 22}} />
      <Check>Remind people to vote !</Check>
      <Check>Let people know you're voting !!</Check>
      <Check>Pass it on !!!</Check>
      <View style={{height: 34}} />
      <Button style={S.center} mode="contained" onPress={() => nav.push(Share)}>
        Enter
      </Button>
      <View style={{height: 120}} />
    </View>
  );
};

const S = {
  title: (theme) => ({
    fontSize: 36,
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.colors.primary,
    fontFamily: 'RobotoSlab_700Bold',
    fontVariant: ['small-caps'],
  }),
  belowTitle: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: 18,
    marginTop: 2,
    marginHorizontal: 24,
  },
  page: {
    flex: 1,
    paddingHorizontal: 36,
    maxWidth: 600,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  checkRow: {flexDirection: 'row', alignItems: 'center', marginBottom: 4},
  check: {fontWeight: 'bold', fontSize: 30, marginRight: 14},
  checkText: {
    flexWrap: 'wrap',
    flexShrink: 1,
    fontSize: 20,
    fontWeight: 'bold',
  },
  center: {
    alignSelf: 'center',
  },
};

Enter.id = 'Enter';
Enter.layout = 'full';

export default Enter;
