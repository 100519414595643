// @flow
import * as React from 'react';
import {IconButton, Menu} from 'react-native-paper';
import type {Action, HandlerRef} from './Actions';
import {actionHandlerComponent} from './Actions';
import type {ViewStyleProp} from 'react-native/Libraries/StyleSheet/StyleSheet';
import {StyleSheet, View, Platform} from 'react-native';

export function VerticalDots(props: {
  size?: number,
  style?: ViewStyleProp,
  type?: React.ComponentType<any>,
  color?: string,
  onPress: () => void,
}): React.Node {
  const {size = 18, style, type, color, onPress} = props;
  const ButtonType = type || IconButton;
  return (
    <ButtonType
      icon="dots-vertical"
      style={[styles.iconButton, style]}
      onPress={onPress}
      size={size}
      type={type}
      color={color}
    />
  );
}

const ActionMenu = (props: {actions: Action[]}): React.Node => {
  const {actions} = props;
  const [menuVisible, setMenuVisible] = React.useState(false);
  const left = React.useRef();

  function menuItemSelected(index: number): void {
    handlers[index].current && handlers[index].current();
    setMenuVisible(false);
  }

  function openMenu() {
    setMenuVisible(true);
  }

  function closeMenu() {
    setMenuVisible(false);
  }

  // This hackiness is necessary becuse the Menu.Items are
  // created in a different context. If we want actions to have
  // the react context for their place in the tree, they need
  // to have components created not as children of <Menu>
  const handlers: HandlerRef[] = [];

  const anchor = (
    <IconButton
      onPress={openMenu}
      icon="dots-vertical"
      size={24}
      color="#FFF"
    />
  );

  function onLayout(event) {
    if (Platform.OS === 'web') {
      const layout = event.nativeEvent.layout;
      left.current = {left: layout.x - 150};
    }
  }

  return (
    <View onLayout={onLayout}>
      {actions.map((action, index) => {
        const ActionComponent = actionHandlerComponent(action);
        const handlerRef: HandlerRef = {};
        handlers.push(handlerRef);
        return <ActionComponent key={index} handler={handlerRef} />;
      })}
      <Menu
        visible={menuVisible}
        onDismiss={closeMenu}
        style={[styles.menu, left.current]}
        contentStyle={styles.menuContent}
        anchor={anchor}
      >
        {menuVisible &&
          actions.map((action, index) => (
            <Menu.Item
              key={action.id}
              onPress={() => menuItemSelected(index)}
              style={styles.menuItem}
              icon={action.icon}
              title={action.label}
            />
          ))}
      </Menu>
    </View>
  );
};

const styles = StyleSheet.create({
  menu: {
    margin: 0,
    padding: 0,
    marginTop: 40,
    zIndex: 1,
    position: 'absolute',
  },
  menuContent: {
    paddingVertical: 0,
    shadowColor: '#000',
    shadowOffset: {width: 2, height: 2},
    shadowOpacity: 0.4,
    shadowRadius: 6,
    paddingBottom: 0,
    right: 0,
  },
  menuItem: {
    borderBottomWidth: 1,
    height: 48,
    paddingVertical: 3,
    borderColor: '#F0F0F0',
    backgroundColor: '#FFF',
  },
  iconButton: {opacity: 0.5},
});

export default ActionMenu;
