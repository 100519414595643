// @flow

import * as React from 'react';
import Promised from './Promised';
import {nonNull} from './Stuff';

export interface HasLoad<Props> {
  load?: AsyncResolver<Props>;
}
export type Loadable<Props> = React.ComponentType<Props> & HasLoad<Props>;

type Async<Props> = $ElementType<Props, 'async'>;
type Sync<Props> = $Diff<Props, {|async: Async<Props>|}>;
type AsyncProps = {async: any, ...};
type AsyncResolver<Props> = (props: Sync<Props>) => Promise<Async<Props>>;

export type ReloadApi = () => void;

// NOTE: This isn't working yet
export const useReload = (): ReloadApi => {
  return nonNull(
    React.useContext(ReloadContext),
    'Must use within a loadable component'
  );
};

const ReloadContext: React.Context<?ReloadApi> = React.createContext();

export function load<Props: AsyncProps>(
  Component: Loadable<Props>
): React.ComponentType<Sync<Props>> {
  const Outer = (props: Sync<Props>): React.Node => {
    const [reloadCount, setReloadCount] = React.useState(0);
    const [doReload, setDoReload] = React.useState(false);

    if (!Component.load) {
      throw new Error('Must have load() method');
    }

    const promise = new Promised(Component.load(props));
    const promiseRef = React.useRef(promise);

    // This has to happen sync during component render for hook semantics
    if (doReload && Component.load) {
      promiseRef.current = new Promised(promise);
      setDoReload(false);
      setReloadCount(reloadCount + 1);
    }

    const api = () => {
      setDoReload(true);
    };

    const Inner = () => {
      const async = promiseRef.current.getValue();

      return (
        <ReloadContext.Provider value={api}>
          <Component {...props} async={async} />
        </ReloadContext.Provider>
      );
    };
    return <Inner {...props} />;
  };
  return Outer;
}
