// @flow

import * as React from 'react';

import {Text, View} from 'react-native';
import {Button} from 'react-native-paper';
import {useCurrentRoute} from './BottomTabs';
import {useNav} from './lib/Nav';
import Vote from './Vote';
import {Animated} from 'react-native';
import {usePulse} from './lib/Animation';
import {IconButton} from 'react-native-paper';
import {FOOTER} from './lib/Links';
import {newBrowserWindow} from './lib/Links';
import {TouchableOpacity} from 'react-native';
import {daysLeftString} from './lib/Stuff';
import Spread from './Spread';
import Plan from './Plan';
import SharePlan from './SharePlan';

type Props = {drawAttention?: boolean, planView: string};

const BottomNav = (props: Props): React.Node => {
  const {drawAttention, planView} = props;
  const [pulse, animation] = usePulse([2.2, 1, 1.8, 1, 1.4, 1], 400);
  const [hasAnimated, setHasAnimated] = React.useState(false);
  const route = useCurrentRoute();
  const nav = useNav();
  const isShare =
    route == 'Share' ||
    route == 'Send' ||
    route == 'Spread' ||
    route == 'SharePlan';
  const animate = drawAttention && !hasAnimated;
  const sharePageNavLabel = '<<\u00A0PLAN YOUR VOTE';

  const shareText =
    planView === 'vote' ? 'SPREAD THE VOTE ♥' : 'SHARE YOUR PLAN';

  const votePageNavLabel = '\u00A0' + shareText + '\u00A0>>';
  const PlanScreen = planView === 'vote' ? Vote : Plan;
  const ShareScreen = planView === 'vote' ? Spread : SharePlan;

  const label = isShare ? sharePageNavLabel : votePageNavLabel;

  function go() {
    const dest = isShare ? PlanScreen : ShareScreen;
    nav.push(dest);
  }

  function help() {
    const to = 'help@spreadthevote.net';
    const subject = 'Feedback / Help for Spread the Vote';
    const url =
      'mailto:?subject=' +
      encodeURIComponent(subject) +
      '&to=' +
      encodeURIComponent(to) +
      '&body=' +
      encodeURIComponent('\n\n' + FOOTER);

    newBrowserWindow(url);
  }

  if (animate) {
    animation.start(() => setHasAnimated(true));
  }
  const headerStyle = animate ? pulse : {opacity: hasAnimated ? 1 : 0};
  const attention =
    planView === 'vote'
      ? `ONLY ${daysLeftString().toUpperCase()}!\nTIME TO DO YOUR PART AND`
      : "AWESOME !!!\u00A0NOW IT'S TIME TO";

  return (
    <View style={S.bottom}>
      {(animate || hasAnimated) && (
        <Animated.View
          style={[headerStyle, {marginBottom: -10, paddingTop: 10}]}
        >
          <TouchableOpacity onPress={go} activeOpacity={0.05}>
            <Text style={[S.bottomText]}>{attention}</Text>
          </TouchableOpacity>
        </Animated.View>
      )}
      <IconButton
        icon="help-circle-outline"
        style={{right: 4, bottom: 6, position: 'absolute'}}
        onPress={help}
        color="#202868"
      />
      <Button labelStyle={S.bottomText} onPress={go} uppercase={false}>
        {label}
      </Button>
    </View>
  );
};

const S = {
  bottom: {
    alignItems: 'center',
    paddingVertical: 8,
    backgroundColor: '#E8E8E8',
    borderRadius: 20,
    borderWidth: 1,
    borderColor: '#C0C0C0',
    margin: 8,
  },
  bottomText: {
    fontSize: 22,
    textAlign: 'center',
    color: '#202868',
    fontFamily: 'RobotoSlab_700Bold',
  },
};

export default (React.memo(BottomNav): React.ComponentType<Props>);
