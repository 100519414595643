// @flow

import * as React from 'react';

// Note: This won't work for mobile the same way, need async
// This also doesn't work if modified by 2 separate windows
export function useLocalStorage(
  key: string,
  defaultValue: string
): [string, (string) => void] {
  const [value, setValue] = React.useState(localStorage[key] ?? defaultValue);

  function updateValue(newValue: string) {
    localStorage[key] = newValue;
    setValue(newValue);
  }
  return [value, updateValue];
}

export function useLocalStorageBool(key: string): [boolean, (boolean) => void] {
  const [value, setValue] = React.useState(getBool(key));

  function updateValue(newValue: boolean) {
    setBool(key, newValue);
    setValue(newValue);
  }
  return [value, updateValue];
}

export function setBool(key: string, value: boolean) {
  localStorage[key] = value ? 'on' : 'off';
}

export function getBool(key: string): boolean {
  return localStorage[key] === 'on';
}
