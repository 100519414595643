// @flow

import * as React from 'react';
import {Text, View} from 'react-native';

const Fallback = () => {
  return (
    <View style={{padding: 24}}>
      <Text>Loading...</Text>
    </View>
  );
};

const BasicFrame = (props: {children: React.Node}): React.Node => {
  return (
    <React.Suspense fallback={<Fallback />}>{props.children}</React.Suspense>
  );
};

export default BasicFrame;
