// @flow
/* global require */
import * as React from 'react';
import {Animated, Text} from 'react-native';
import {useNav} from './lib/Nav';
import {useFadeIn} from './lib/Animation';
import {onceOnLoad, onLoad} from './lib/Lifecycle';
import {type Screen} from './lib/Screen';
import {Image} from 'react-native';
import {View} from 'react-native';
import Vote from './Vote';
import {Platform} from 'react-native';
import Soon from './Soon';
import {useAccount} from './lib/Auth';
import {VoteApi} from './Api';
import Spread from './Spread';
import {getPlanView, getSharePrompt} from './lib/Stuff';
import {getShowDaysLeft} from './lib/Stuff';
import Plan from './Plan';

const HOLD = 1000;
const SPLASH_DELAY = 2000;

function isLocked() {
  if (Platform.OS === 'web') {
    const locked = localStorage['unlocked'] !== 'true';
    return false && locked;
  }
  return false;
}

const Splash: Screen<{}> = () => {
  const [shouldRedirect, setShouldRedirect] = React.useState(false);
  const [fadeIn, animation] = useFadeIn(SPLASH_DELAY);
  const user = useAccount();
  const nav = useNav();
  const PlanView = getPlanView() == 'vote' ? Vote : Plan;

  React.useEffect(() => {
    if (user) {
      VoteApi.preloads(user.id);
      // To set user properties
      getSharePrompt();
      getShowDaysLeft();
    }
  }, [user]);

  function fader() {
    animation.start(() => {
      setTimeout(() => setShouldRedirect(true), HOLD);
    });
  }

  onceOnLoad(fader);
  onLoad(() => {
    if (shouldRedirect) {
      const redirectTo = isLocked()
        ? Soon
        : getPlanView() === 'plan'
        ? PlanView
        : Spread;
      nav.push(redirectTo);
    }
  });

  const img = require('../assets/voteheart.png');

  return (
    <Animated.View style={[fadeIn, styles.full]}>
      <Text style={styles.splashFont}>Spread{'\n'}The</Text>
      <View style={{height: 8}} />
      <Image
        style={{width: 256, height: 256, resizeMode: 'contain'}}
        source={{uri: img}}
      />
    </Animated.View>
  );
};

Splash.id = 'Splash';
Splash.layout = 'full';

const styles = {
  full: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#202868',
    padding: 40,
  },
  splashFont: {
    fontFamily: 'RobotoSlab_700Bold',
    fontSize: 90,
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#FFF',
    fontVariant: ['small-caps'],
    marginLeft: -12,
  },
};

export default Splash;
