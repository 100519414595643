// @flow
import * as React from 'react';
import {View, Image} from 'react-native';
import {Button, Card} from 'react-native-paper';
import {useFadeIn} from './lib/Animation';
import {Animated} from 'react-native';
import {onceOnLoad} from './lib/Lifecycle';

import {type Screen} from './lib/Screen';
import {ScrollView} from 'react-native';
import {SectionTitle, BodyText, InfoText} from './Styles';
import {facebook, isDesktopWeb, share, sms} from './lib/Links';
import {twitter} from './lib/Links';
import {email} from './lib/Links';
import {logEvent} from './lib/Logging';
import {type VotePlan, VoteApi} from './Api';
import {useAuth} from './lib/Auth';
import {useAccount} from './lib/Auth';
import BottomNav from './BottomNav';
import Reach from './Reach';

type Sharer = {
  icon: string,
  label: string,
  share: (string, string, plan?: string) => void,
};

const SHARERS: {[string]: Sharer} = {
  facebook: {icon: 'facebook', label: 'Facebook', share: facebook},
  twitter: {icon: 'twitter', label: 'Twitter', share: twitter},
  email: {icon: 'email', label: 'Email', share: email},
  sms: {icon: 'message-outline', label: 'SMS', share: sms},
};

const ShareButton = (props: {
  type: string,
  msg: string,
  from: string,
  plan: string,
  onPress?: () => void,
}) => {
  const {type, msg, from, plan, onPress} = props;
  const sharer = SHARERS[type];
  const user = useAccount();
  if (!sharer) {
    return <></>;
  }

  function share() {
    logEvent('action_share_' + type);
    if (user) {
      VoteApi.recordEvent(user.id, 'sharing');
    }
    sharer.share(msg, from, plan);
    onPress && onPress();
  }

  return (
    <Button
      icon={sharer.icon}
      mode="outlined"
      style={{backgroundColor: '#FFF', marginBottom: 12, marginRight: 12}}
      onPress={share}
    >
      {sharer.label}
    </Button>
  );
};

type Props = {
  async: {
    from: string,
    plan: VotePlan,
  },
};

const WHERES = {
  bymail: '1',
  inperson: '2',
};
function planToPlan(plan: VotePlan): string {
  return (
    (plan.registered ? '1' : '0') +
    (plan.where ? WHERES[plan.where] : '0') +
    (plan.voted ? '1' : '0')
  );
}

const TYPES = ['100', '110', '111', '120', '121'];

const BODY_MSG =
  'Hi everyone - wanted to share the latest update on my plan to vote.\n\nHoping you all will Spread the Vote ♥ too!';

const SharePlan: Screen<Props> = (props: Props) => {
  const [fadeIn, animation] = useFadeIn(2000);
  const [hasShared, setHasShared] = React.useState(
    localStorage['shared'] == 'true'
  );
  const {from} = props.async;
  const [imageViewWidth, setImageViewWidth] = React.useState(0);
  const user = useAccount();

  const planId = planToPlan(props.async.plan);
  let img;

  if (TYPES.indexOf(planId) != -1) {
    img = 'https://spreadthevote.net/assets/Plan' + planId + '.png';
  } else {
    img = 'https://spreadthevote.net/assets/voteheart_for_share2.png';
  }

  onceOnLoad(() => animation.start());

  function onImageLayout(event) {
    const layout = event.nativeEvent.layout;
    setImageViewWidth(layout.width);
  }

  function shareSheet() {
    share(BODY_MSG, from, planId);
    logEvent('action_share_sheet');
    if (user) {
      VoteApi.recordEvent(user.id, 'sharing');
    }
    markHasShared();
  }

  function markHasShared() {
    localStorage['shared'] = 'true';
    setHasShared(true);
  }

  return (
    <View style={S.container}>
      <ScrollView contentContainerStyle={S.scroll}>
        <Animated.View style={fadeIn}>
          <InfoText>
            The biggest motivator for people to vote is seeing their friends
            make a concrete plan to vote.
          </InfoText>
          <SectionTitle top={8}>Preview</SectionTitle>
          <View style={{height: 6}} />
          <Card elevation={6} style={{shadowOffset: {width: 4, height: 4}}}>
            <Card.Content style={{padding: 0}}>
              <BodyText style={{padding: 12}}>{BODY_MSG}</BodyText>
              <View style={{height: 14}} />
              <View onLayout={onImageLayout}>
                <Image
                  style={{
                    width: imageViewWidth,
                    height: imageViewWidth * 0.55,
                    resizeMode: 'center',
                  }}
                  source={{uri: img}}
                />
              </View>
            </Card.Content>
          </Card>
          <View style={{height: 12}} />
          <SectionTitle>Send</SectionTitle>
          <InfoText>
            You'll be able to edit your message before sending
          </InfoText>
          <View style={{height: 12}} />
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
            }}
          >
            <ShareButton
              type="twitter"
              msg={BODY_MSG}
              from={from}
              plan={planId}
              onPress={markHasShared}
            />
            <ShareButton
              type="facebook"
              msg={BODY_MSG}
              from={from}
              plan={planId}
              onPress={markHasShared}
            />
            <ShareButton
              type="email"
              msg={BODY_MSG}
              from={from}
              plan={planId}
              onPress={markHasShared}
            />
            {!isDesktopWeb() && (
              <ShareButton
                type="sms"
                msg={BODY_MSG}
                from={from}
                plan={planId}
                onPress={markHasShared}
              />
            )}

            {!isDesktopWeb() && (
              <Button onPress={shareSheet} style={S.share}>
                More...
              </Button>
            )}
          </View>
          {hasShared && <Reach />}
        </Animated.View>
      </ScrollView>
      <BottomNav planView="plan" />
    </View>
  );
};

const S = {
  scroll: {
    flex: 1,
    alignItems: 'stretch',
    padding: 24,
    flexGrow: 1,
  },
  container: {
    flex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
  },
  share: {marginBottom: 12, marginRight: 12},
};

SharePlan.id = 'SharePlan';
SharePlan.title = 'Share your Plan ♥';

SharePlan.load = async () => {
  // Ensure user is loaded by awaiting call
  const user = await useAuth().getUser();
  const from = user ? await VoteApi.getFromId(user.id) : ' ';
  return {from, plan: await VoteApi.getPlan(user?.id)};
};

export default SharePlan;
