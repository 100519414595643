// @flow

import * as firebase from 'firebase/app';

import 'firebase/functions';
import {getChannel} from './Dev';
import {getGlobal, getUserData, setGlobal, setUserData} from './lib/Firestore';

const useEmulator = false;

function hostedApi<IN, OUT>(name: string): (IN) => Promise<OUT> {
  return async (req: IN) => {
    if (useEmulator) {
      firebase.functions().useFunctionsEmulator('http://localhost:5001');
    }
    const fullReq = {...req, chan: getChannel()};
    const serverApi = firebase.functions().httpsCallable(name);
    const result: OUT = (await serverApi(fullReq)).data;
    return result;
  };
}

const getVoteCount = async (): Promise<number> => {
  const result = cachedGvc ?? (await getGlobal('votes')) ?? 12123;
  cachedGvc = result;
  return result;
};

export type VotePlan = {
  registered: boolean,
  where: ?string,
  voted: boolean,
};

/*
const getVoteCountApi: ({}) => Promise<{votes: number}> = hostedApi(
  'getVoteCount'
);*/

const getStatsApi: ({}) => Promise<any> = hostedApi('getStats');
const nextIdApi: ({}) => Promise<{id: number}> = hostedApi('nextId');
const geocode: () => Promise<{country: string, region: string}> = hostedApi(
  'geocode'
);
const getReach: () => Promise<{
  viewReach: number,
  voteReach: number,
}> = hostedApi('getReach');

const isVoting = async (userId: ?string): Promise<boolean> => {
  if (userId == null) {
    return false;
  }
  const status = await getUserData(userId, 'votestatus');
  return status?.voting ?? false;
};

const NO_STATUS: VotePlan = {
  registered: false,
  where: null,
  voted: false,
};

const getPlan = async (userId: ?string): Promise<VotePlan> => {
  const oldVoting = await isVoting(userId);

  if (!userId) {
    return NO_STATUS;
  }
  const result = (await getUserData(userId, 'plan')) || {...NO_STATUS};
  result.registered = result.registered || oldVoting;

  return result;
};

let cachedGvc;
let cachedGeocode;
let cachedReach = {};

export const VoteApi = {
  isVoting,

  setVoting: async function (userId: string, voting: boolean): Promise<void> {
    cachedReach = {};
    await setUserData(userId, 'votestatus', {voting});
  },

  requestInvite: async function (userId: string, email: string): Promise<void> {
    await setUserData(userId, 'requestinvite', {email});
  },

  getFromId: async function (userId: string): Promise<string> {
    // This could be centralized on the server
    const fromId = await getUserData(userId, 'fromId');
    if (fromId?.id) {
      return String(fromId.id);
    } else {
      const nextIdResult = await nextIdApi({});
      await setUserData(userId, 'fromId', nextIdResult);
      return String(nextIdResult.id);
    }
  },

  recordEvent: async function (userId: string, event: string): Promise<void> {
    const key = 'event_' + event;
    const from = Object.keys(JSON.parse(localStorage.getItem('refs') || '{}'));
    const existingAction = await getUserData(userId, key);
    if (existingAction) {
      return;
    }
    await setUserData(userId, key, {name: event, from, time: Date.now()});
  },

  getVoteCount,

  incrementVoteCount: async (): Promise<number> => {
    const votes = await getVoteCount();
    cachedGvc = votes + 1;
    setGlobal('votes', cachedGvc);
    return cachedGvc;
  },

  decrementVoteCount: async (): Promise<number> => {
    const votes = await getVoteCount();
    cachedGvc = votes - 1;
    setGlobal('votes', cachedGvc);
    return cachedGvc;
  },

  getStats: async (): Promise<any> => {
    return await getStatsApi({});
  },

  nextId: async (): Promise<number> => {
    const result = await nextIdApi({});
    return result.id;
  },
  geocode: async (): Promise<{country: string, region: string}> => {
    const result = cachedGeocode ?? (await geocode());
    cachedGeocode = result;
    return result;
  },
  getReach: async function (
    userId: string
  ): Promise<{
    viewReach: number,
    voteReach: number,
  }> {
    const result = cachedReach[userId] ?? (await getReach());
    cachedReach[userId] = result;
    return result;
  },
  getPlan,

  preloads: async (userId: string): Promise<void> => {
    VoteApi.getVoteCount();
    VoteApi.getFromId(userId);
    VoteApi.isVoting(userId);
    VoteApi.geocode(); // Should ideally cache
  },
};
