// @flow

import * as React from 'react';
import {nonNull} from './Stuff';

export type User = {
  id: string,
  name: string,
  pic?: string,
  email?: string,
  phone?: string,
  type: string,
};

// Going to try to use both for clients and for providers
// but this could be a mistake
export type AuthApi = {
  logout: () => Promise<void>,
  getUser: () => Promise<?User>,
  tryLogin: (showPrivacy?: () => void) => Promise<?User>,
};

const UserContext: React.Context<?User> = React.createContext();
const AuthApiContext: React.Context<?AuthApi> = React.createContext();

export const useAccount = (): ?User => {
  return React.useContext(UserContext);
};

export const requireAccount = (): User => {
  return nonNull(React.useContext(UserContext), 'You must be logged in');
};

export const useAuth = (): AuthApi => {
  return nonNull(
    React.useContext(AuthApiContext),
    'Must use within AuthProvider'
  );
};

type Props = {
  children: React.Node,
  api: AuthApi,
  showPrivacy?: () => {},
};

export const AuthProvider = (props: Props): React.Node => {
  const {api, children} = props;
  const [user, setUser] = React.useState<?User>();

  function maybeUpdateUser(newUser: ?User) {
    if (user?.id !== newUser?.id) {
      setUser(newUser);
    }
  }

  const clientApi: AuthApi = {
    logout: async () => {
      await api.logout();
      setUser(null);
    },
    getUser: async () => {
      const newUser = await api.getUser();
      maybeUpdateUser(newUser);
      return newUser;
    },
    tryLogin: async (showPrivacy?: () => void) => {
      const newUser = await api.tryLogin(showPrivacy);
      maybeUpdateUser(newUser);
      return newUser;
    },
  };

  // This triggers initial login
  clientApi.getUser();

  return (
    <UserContext.Provider value={user}>
      <AuthApiContext.Provider value={clientApi}>
        {children}
      </AuthApiContext.Provider>
    </UserContext.Provider>
  );
};
