// @flow

import * as React from 'react';
import {Text, View} from 'react-native';
import {type Screen} from './lib/Screen';
import {useAccount} from './lib/Auth';
import {Button} from 'react-native-paper';
import {useLocalStorageBool} from './lib/Storage';
import Constants from 'expo-constants';
import {VoteApi} from './Api';
import {useAuth} from './lib/Auth';

export const DEVMODE = 'DEVMODE';
declare var __DEV__: boolean;

type Channel = 'dev' | 'staging' | 'prod';

export function getChannel(): Channel {
  if (Constants.manifest?.extra?.channel) {
    return Constants.manifest?.extra?.channel;
  }
  if (__DEV__) {
    return 'dev';
  }
  return 'staging';
}

export const useDevMode: () => [boolean, (boolean) => void] = () =>
  useLocalStorageBool(DEVMODE);

const Dev: Screen<{}> = () => {
  const user = useAccount();
  const auth = useAuth();
  const [devMode, setDevMode] = useDevMode();
  const [nextId, setNextId] = React.useState('');
  const [fromId, setFromId] = React.useState('');
  const [reach, setReach] = React.useState();

  const userText = user
    ? 'You are logged in with ID: ' + user.id
    : 'Not logged in';
  const typeText = user ? user.type : null;

  async function doNextId() {
    const nextId = await VoteApi.nextId();
    setNextId(nextId);
  }

  async function getMyFromId() {
    if (user) {
      try {
        const fromId = await VoteApi.getFromId(user.id);
        setFromId(fromId);
      } catch (e) {
        console.log(e);
      }
    }
  }
  React.useEffect(() => {
    getMyFromId();
  }, [user]);

  async function tryRead() {
    const uid = 'KTM5bPDP1afvStHKMEDczFa9x2G2';
    console.log(await VoteApi.isVoting(uid));
  }

  async function stats() {
    console.log(await VoteApi.getStats());
  }

  async function geocode() {
    console.log(await VoteApi.geocode());
  }

  async function getReach() {
    setReach(await VoteApi.getReach(user?.id || ''));
  }

  return (
    <View style={{padding: 40}}>
      <Text>{userText}</Text>
      <View style={{height: 12}} />
      <Text>{typeText}</Text>
      <View style={{height: 12}} />
      <Text>Dev Mode: {String(devMode)}</Text>
      <Text>Channel: {getChannel()}</Text>
      <Text>NextId: {nextId}</Text>
      <Text>FromId: {fromId}</Text>
      <Text>View Reach: {reach?.viewReach}</Text>
      <Text>Vote Reach: {reach?.voteReach}</Text>

      <DevButton label="Toggle Dev Mode" fn={() => setDevMode(!devMode)} />
      <DevButton label="Next ID" fn={doNextId} />
      <DevButton label="Try read" fn={tryRead} />
      <DevButton label="Log Out" fn={() => auth.logout()} />
      <DevButton label="Stats" fn={stats} />
      <DevButton label="Geocode" fn={geocode} />
      <DevButton label="Get Reach" fn={getReach} />
    </View>
  );
};

const DevButton = (props: {
  label: string,
  fn: () => void | Promise<void>,
}): React.Node => {
  const {label, fn} = props;
  return (
    <>
      <View style={{height: 12}} />
      <Button
        style={{alignSelf: 'center', width: 250}}
        mode="outlined"
        onPress={() => fn()}
      >
        {label}
      </Button>
    </>
  );
};

Dev.id = 'Dev';
Dev.title = 'Dev Settings';
Dev.back = true;

export default Dev;
