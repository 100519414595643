// @flow
import 'firebase/auth';

import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';

let firebaseAuthUi;

// TODO: We need to start allowing config params here and stuff
export async function logInUi(showPrivacy?: () => void): Promise<boolean> {
  if (!firebaseAuthUi) {
    firebaseAuthUi = new firebaseui.auth.AuthUI(firebase.auth());
  }

  // Hackalicous way to interact with static HTML content
  if (showPrivacy) {
    window.showPrivacy = showPrivacy;
  }

  firebaseAuthUi.start('#firebaseui-auth-container', {
    callbacks: {
      signInSuccessWithAuthResult: function () {
        hide(['fbauth1', 'fbauth2']);
        resolver && resolver(true);
        return false;
      },
      uiShown: function () {
        show(['fbauth1', 'fbauth2']);
      },
      signInFailure: function (err) {
        hide(['fbauth1', 'fbauth2']);
        if (err.code == 'firebaseui/anonymous-upgrade-merge-conflict') {
          firebase
            .auth()
            .signInWithCredential(err.credential)
            .then(() => {
              resolver && resolver(true);
            });
        } else {
          resolver && resolver(false);
        }
      },
    },
    autoUpgradeAnonymousUsers: true,
    signInFlow: 'popup',
    signInOptions: [
      {provider: 'facebook.com', scopes: ['email', 'public_profile']},
      {provider: 'google.com', scopes: ['email', 'profile']},
    ],
  });

  let resolver;

  window.authDismissed = () => resolver && resolver(false);

  return new Promise((resolve) => {
    resolver = resolve;
  });
}

function hide(ids: string[]) {
  setDisplay(ids, 'none');
}

function show(ids: string[]) {
  setDisplay(ids, 'block');
}

function setDisplay(ids: string[], display: string) {
  for (const id of ids) {
    const style = document.getElementById(id)?.style;
    if (style) {
      style.display = display;
    }
  }
}
