// @flow

const states = [
  {
    state: 'Alabama',
    abbr: 'AL',
    reg_in_person: 'Oct 19',
    reg_by_mail: 'Oct 19',
    reg_received: '',
    reg_online: 'Oct 19',
    poll_finder:
      'https://myinfo.alabamavotes.gov/VoterView/PollingPlaceSearch.do',
  },
  {
    state: 'Alaska',
    abbr: 'AK',
    reg_in_person: 'Oct 4',
    reg_by_mail: 'Oct 3',
    reg_received: '',
    reg_online: 'Oct 4',
    poll_finder: 'https://myvoterinformation.alaska.gov/',
  },
  {
    state: 'Arizona',
    abbr: 'AZ',
    reg_in_person: 'Oct 5',
    reg_by_mail: 'Oct 5',
    reg_received: '',
    reg_online: 'Oct 5',
    poll_finder: 'https://my.arizona.vote/PortalList.aspx',
  },
  {
    state: 'Arkansas',
    abbr: 'AR',
    reg_in_person: 'Oct 5',
    reg_by_mail: 'Oct 5',
    reg_received: '',
    reg_online: '',
    poll_finder: 'https://www.voterview.ar-nova.org/voterview',
  },
  {
    state: 'California',
    abbr: 'CA',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 19',
    reg_received: '',
    reg_online: 'Oct 19',
    poll_finder: 'https://www.sos.ca.gov/elections/polling-place/',
  },
  {
    state: 'Colorado',
    abbr: 'CO',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 26',
    reg_received: 'Y',
    reg_online: 'Oct 26',
    poll_finder:
      'https://www.sos.state.co.us/pubs/elections/Resources/CountyElectionOffices.html',
  },
  {
    state: 'Connecticut',
    abbr: 'CT',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 27',
    reg_received: '',
    reg_online: 'Oct 27',
    poll_finder: 'https://portaldir.ct.gov/sots/LookUp.aspx',
  },
  {
    state: 'Delaware',
    abbr: 'DE',
    reg_in_person: 'Oct 10',
    reg_by_mail: 'Oct 10',
    reg_received: '',
    reg_online: 'Oct 10',
    poll_finder: 'https://ivote.de.gov/VoterView',
  },
  {
    state: 'D.C.',
    abbr: 'DC',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 13',
    reg_received: 'Y',
    reg_online: 'Oct 13',
    poll_finder: 'https://www.dcboe.org/Voters/Where-to-Vote/Polling-Places',
  },
  {
    state: 'Florida',
    abbr: 'FL',
    reg_in_person: 'Oct 5',
    reg_by_mail: 'Oct 5',
    reg_received: '',
    reg_online: 'Oct 5',
    poll_finder:
      'https://registration.elections.myflorida.com/CheckVoterStatus',
  },
  {
    state: 'Georgia',
    abbr: 'GA',
    reg_in_person: 'Oct 5',
    reg_by_mail: 'Oct 5',
    reg_received: '',
    reg_online: 'Oct 5',
    poll_finder: 'https://www.mvp.sos.ga.gov/MVP/mvp.do',
  },
  {
    state: 'Hawaii',
    abbr: 'HI',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 5',
    reg_received: '',
    reg_online: 'Oct 5',
    poll_finder: 'https://olvr.hawaii.gov/altpollingplacesearch.aspx',
  },
  {
    state: 'Idaho',
    abbr: 'ID',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 9',
    reg_received: '',
    reg_online: 'Oct 9',
    poll_finder:
      'https://elections.sos.idaho.gov/ElectionLink/ElectionLink/ViewPollingLocation.aspx',
  },
  {
    state: 'Illinois',
    abbr: 'IL',
    reg_in_person: 'Oct 13',
    reg_by_mail: 'Oct 6',
    reg_received: '',
    reg_online: 'Oct 18',
    poll_finder: 'https://ova.elections.il.gov/PollingPlaceLookup.aspx',
  },
  {
    state: 'Indiana',
    abbr: 'IN',
    reg_in_person: 'Oct 5',
    reg_by_mail: 'Oct 5',
    reg_received: '',
    reg_online: 'Oct 5',
    poll_finder: 'https://indianavoters.in.gov/',
  },
  {
    state: 'Iowa',
    abbr: 'IA',
    reg_in_person: 'Oct 24',
    reg_by_mail: 'Oct 19',
    reg_received: 'Y',
    reg_online: 'Oct 24',
    poll_finder:
      'https://sos.iowa.gov/elections/voterreg/pollingplace/search.aspx',
  },
  {
    state: 'Kansas',
    abbr: 'KS',
    reg_in_person: 'Oct 13',
    reg_by_mail: 'Oct 13',
    reg_received: '',
    reg_online: 'Oct 13',
    poll_finder:
      'https://myvoteinfo.voteks.org/VoterView/PollingPlaceSearch.do',
  },
  {
    state: 'Kentucky',
    abbr: 'KY',
    reg_in_person: 'Oct 5',
    reg_by_mail: 'Oct 5',
    reg_received: '',
    reg_online: 'Oct 5',
    poll_finder: 'https://vrsws.sos.ky.gov/vic/',
  },
  {
    state: 'Louisiana',
    abbr: 'LA',
    reg_in_person: 'Oct 5',
    reg_by_mail: 'Oct 5',
    reg_received: '',
    reg_online: 'Oct 14',
    poll_finder: 'https://voterportal.sos.la.gov/',
  },
  {
    state: 'Maine',
    abbr: 'ME',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 13',
    reg_received: '',
    reg_online: '',
    poll_finder:
      'https://www1.maine.gov/portal/government/edemocracy/voter_lookup.php',
  },
  {
    state: 'Maryland',
    abbr: 'MD',
    reg_in_person: 'Oct 13',
    reg_by_mail: 'Oct 13',
    reg_received: '',
    reg_online: 'Oct 13',
    poll_finder: 'https://elections.maryland.gov/voting/where.html',
  },
  {
    state: 'Massachusetts',
    abbr: 'MA',
    reg_in_person: 'Oct 24',
    reg_by_mail: 'Oct 24',
    reg_received: '',
    reg_online: 'Oct 24',
    poll_finder:
      'https://www.sec.state.ma.us/wheredoivotema/bal/MyElectionInfo.aspx',
  },
  {
    state: 'Michigan',
    abbr: 'MI',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 19',
    reg_received: '',
    reg_online: 'Oct 19',
    poll_finder: 'https://mvic.sos.state.mi.us/',
  },
  {
    state: 'Minnesota',
    abbr: 'MN',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 13',
    reg_received: 'Y',
    reg_online: 'Oct 13',
    poll_finder: 'https://pollfinder.sos.state.mn.us/',
  },
  {
    state: 'Mississippi',
    abbr: 'MS',
    reg_in_person: 'Oct 5',
    reg_by_mail: 'Oct 5',
    reg_received: '',
    reg_online: '',
    poll_finder: 'https://www.sos.ms.gov/PollingPlace/Pages/default.aspx',
  },
  {
    state: 'Missouri',
    abbr: 'MO',
    reg_in_person: 'Oct 7',
    reg_by_mail: 'Oct 7',
    reg_received: '',
    reg_online: 'Oct 7',
    poll_finder:
      'https://voteroutreach.sos.mo.gov/PRD/VoterOutreach/VOSearch.aspx',
  },
  {
    state: 'Montana',
    abbr: 'MT',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 5',
    reg_received: 'Y',
    reg_online: '',
    poll_finder: 'https://app.mt.gov/voterinfo/',
  },
  {
    state: 'Nebraska',
    abbr: 'NE',
    reg_in_person: 'Oct 23',
    reg_by_mail: 'Oct 16',
    reg_received: 'Y',
    reg_online: 'Oct 16',
    poll_finder:
      'https://www.votercheck.necvr.ne.gov/VoterView/PollingPlaceSearch.do',
  },
  {
    state: 'Nevada',
    abbr: 'NV',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 6',
    reg_received: '',
    reg_online: 'Oct 29',
    poll_finder: 'https://www.nvsos.gov/votersearch/',
  },
  {
    state: 'New Hampshire',
    abbr: 'NH',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 21',
    reg_received: '',
    reg_online: '',
    poll_finder: 'https://app.sos.nh.gov/Public/PollingPlaceSearch.aspx',
  },
  {
    state: 'New Jersey',
    abbr: 'NJ',
    reg_in_person: 'Oct 13',
    reg_by_mail: 'Oct 13',
    reg_received: '',
    reg_online: '',
    poll_finder: 'https://voter.svrs.nj.gov/polling-place-search',
  },
  {
    state: 'New Mexico',
    abbr: 'NM',
    reg_in_person: 'Oct 31',
    reg_by_mail: 'Oct 6',
    reg_received: '',
    reg_online: 'Oct 6',
    poll_finder:
      'https://voterportal.servis.sos.state.nm.us/WhereToVoteAddress.aspx',
  },
  {
    state: 'New York',
    abbr: 'NY',
    reg_in_person: 'Oct 9',
    reg_by_mail: 'Oct 9',
    reg_received: 'Y',
    reg_online: 'Oct 9',
    poll_finder: 'https://voterlookup.elections.ny.gov/',
  },
  {
    state: 'North Carolina',
    abbr: 'NC',
    reg_in_person: 'Oct 9',
    reg_by_mail: 'Oct 9',
    reg_received: 'Y',
    reg_online: 'Oct 9',
    poll_finder: 'https://vt.ncsbe.gov/PPLkup/',
  },
  {
    state: 'North Dakota',
    abbr: 'ND',
    reg_in_person: '',
    reg_by_mail: '',
    reg_received: '',
    reg_online: '',
    poll_finder: 'https://vip.sos.nd.gov/wheretovote.aspx',
  },
  {
    state: 'Ohio',
    abbr: 'OH',
    reg_in_person: 'Oct 5',
    reg_by_mail: 'Oct 5',
    reg_received: '',
    reg_online: 'Oct 5',
    poll_finder: 'https://voterlookup.ohiosos.gov/VoterLookup.aspx',
  },
  {
    state: 'Oklahoma',
    abbr: 'OK',
    reg_in_person: 'Oct 9',
    reg_by_mail: 'Oct 9',
    reg_received: '',
    reg_online: '',
    poll_finder: 'https://okvoterportal.okelections.us/',
  },
  {
    state: 'Oregon',
    abbr: 'OR',
    reg_in_person: 'Oct 13',
    reg_by_mail: 'Oct 13',
    reg_received: 'Y',
    reg_online: 'Oct 13',
    poll_finder: 'https://sos.oregon.gov/voting/Pages/drop-box-locator.aspx',
  },
  {
    state: 'Pennsylvania',
    abbr: 'PA',
    reg_in_person: 'Oct 19',
    reg_by_mail: 'Oct 19',
    reg_received: 'Y',
    reg_online: 'Oct 19',
    poll_finder:
      'https://www.pavoterservices.pa.gov/Pages/PollingPlaceInfo.aspx',
  },
  {
    state: 'Rhode Island',
    abbr: 'RI',
    reg_in_person: 'Oct 4',
    reg_by_mail: 'Oct 3',
    reg_received: 'Y',
    reg_online: 'Oct 4',
    poll_finder: 'https://vote.sos.ri.gov/Home/PollingPlaces?ActiveFlag=2',
  },
  {
    state: 'South Carolina',
    abbr: 'SC',
    reg_in_person: 'Oct 2',
    reg_by_mail: 'Oct 5',
    reg_received: '',
    reg_online: 'Oct 4',
    poll_finder:
      'https://info.scvotes.sc.gov/eng/voterinquiry/VoterInformationRequest.aspx?PageMode=VoterInfo',
  },
  {
    state: 'South Dakota',
    abbr: 'SD',
    reg_in_person: 'Oct 19',
    reg_by_mail: 'Oct 19',
    reg_received: 'Y',
    reg_online: '',
    poll_finder: 'https://vip.sdsos.gov/viplogin.aspx',
  },
  {
    state: 'Tennessee',
    abbr: 'TN',
    reg_in_person: 'Oct 5',
    reg_by_mail: 'Oct 5',
    reg_received: '',
    reg_online: 'Oct 5',
    poll_finder: 'https://web.go-vote-tn.elections.tn.gov/',
  },
  {
    state: 'Texas',
    abbr: 'TX',
    reg_in_person: 'Oct 5',
    reg_by_mail: 'Oct 5',
    reg_received: '',
    reg_online: '',
    poll_finder: 'https://teamrv-mvp.sos.texas.gov/MVP/mvp.do',
  },
  {
    state: 'Utah',
    abbr: 'UT',
    reg_in_person: 'Oct 27',
    reg_by_mail: 'Oct 5',
    reg_received: '',
    reg_online: 'Oct 23',
    poll_finder:
      'https://votesearch.utah.gov/voter-search/search/search-by-address/how-and-where-can-i-vote',
  },
  {
    state: 'Vermont',
    abbr: 'VT',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Nov 3',
    reg_received: 'Y',
    reg_online: 'Nov 3',
    poll_finder: 'https://mvp.sec.state.vt.us/',
  },
  {
    state: 'Virginia',
    abbr: 'VA',
    reg_in_person: 'Oct 13',
    reg_by_mail: 'Oct 13',
    reg_received: '',
    reg_online: 'Oct 13',
    poll_finder: 'https://www.elections.virginia.gov/citizen-portal/index.html',
  },
  {
    state: 'Washington',
    abbr: 'WA',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 26',
    reg_received: 'Y',
    reg_online: 'Oct 26',
    poll_finder: 'https://www.sos.wa.gov/elections/auditors/',
  },
  {
    state: 'West Virginia',
    abbr: 'WV',
    reg_in_person: 'Oct 13',
    reg_by_mail: 'Oct 13',
    reg_received: '',
    reg_online: 'Oct 13',
    poll_finder:
      'https://services.sos.wv.gov/Elections/Voter/FindMyPollingPlace',
  },
  {
    state: 'Wisconsin',
    abbr: 'WI',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 14',
    reg_received: '',
    reg_online: 'Oct 14',
    poll_finder: 'https://myvote.wi.gov/en-US/FindMyPollingPlace',
  },
  {
    state: 'Wyoming',
    abbr: 'WY',
    reg_in_person: 'Nov 3',
    reg_by_mail: 'Oct 20',
    reg_received: '',
    reg_online: '',
    poll_finder: 'https://soswy.state.wy.us/Elections/PollPlace/Default.aspx',
  },
];

type VoteInfo = {
  state: string,
  reg_in_person: string,
  reg_by_mail: string,
  reg_received: string,
  reg_online: string,
  poll_finder: string,
  abbr: string,
};

export const STATES: string[] = states.map((entry) => entry.state);
export const BY_STATE: {[string]: VoteInfo} = {};
states.forEach((info) => (BY_STATE[info.state] = info));

export const BY_ABBR: {[string]: VoteInfo} = {};
states.forEach((info) => (BY_ABBR[info.abbr] = info));
